import React, { useEffect, useState } from "react";
import { Table, Modal } from "antd";
import "../SalesTracker/table.scss";
import _http from "../../Utils/Api/_http";
import view from "../../Assets/feedback/view.png";

const FeedbackTable = () => {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  // to render the datas in the table
  const renderEmptyOrValue = (text, TT, cln) => {
    return text ? (
      <span className={cln} style={{ textTransform: TT && "capitalize" }}>
        {text}
      </span>
    ) : (
      <span className={"empty"}>Empty</span>
    );
  };

  const baseFontSize = 16;

  const rem = (pixels, context = baseFontSize) => {
    if (typeof pixels === "number") {
      pixels = pixels + "px";
    }

    if (typeof context === "number") {
      context = context + "px";
    }

    // Extract numeric values from pixel strings
    const pixelValue = parseFloat(pixels);
    const contextValue = parseFloat(context);

    return `${pixelValue / contextValue}rem`;
  };

  // Columns configuration for Ant Design Table
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await _http.post(
        `https://autodesk.dtskill.com/insert_data`
      );
      setFeedback(response.data.records);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFile = async (ids) => {
    setLoading(true);
    try {
      const response = await _http.get(
        `https://autodesk.dtskill.com/download_docx/${ids}`,
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      setModalContent(
        <embed
          width={"100%"}
          height={"100%"}
          src={URL.createObjectURL(blob)}
          type="application/pdf"
        />
      );
      setModalVisible(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "Time stamp",
      dataIndex: "form_timestamp",
      key: "form_timestamp",
      width: rem(170),
      className: "tl",
      sorter: (a, b) => a.form_timestamp?.localeCompare(b.form_timestamp),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Date",
      dataIndex: "form_date",
      key: "form_date",
      className: "tl",
      sorter: (a, b) => a.form_date?.localeCompare(b.form_date),
      width: rem(110),
      render: (text) => renderEmptyOrValue(text, true),
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      className: "tl",
      sorter: (a, b) => a.company_name.localeCompare(b.company_name),
      width: rem(160),
      render: (text) => renderEmptyOrValue(text, true),
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      className: "tl",
      sorter: (a, b) => a.client_name.localeCompare(b.client_name),
      width: rem(160),
      render: (text) => renderEmptyOrValue(text, true),
    },
    {
      title: "Client Designation ",
      dataIndex: "client_designation",
      key: "client_designation",
      className: "tl",

      sorter: (a, b) =>
        a.client_designation.localeCompare(b.client_designation),
      width: rem(160),
      render: (text) => renderEmptyOrValue(text, true),
    },
    {
      title: "Telephone number",
      dataIndex: "telephone_number",
      key: "telephone_number",
      className: "tl",
      sorter: (a, b) => a.telephone_number.localeCompare(b.telephone_number),
      width: rem(160),
      render: (text) => renderEmptyOrValue(text, true),
    },

    {
      title: "Email Address",
      dataIndex: "email_address",
      key: "email_address",
      className: "tl",

      sorter: (a, b) => a.email_address.localeCompare(b.email_address),
      width: rem(200),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Quality rate",
      dataIndex: "quality_rate",
      key: "quality_rate",
      width: rem(160),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Service experience rate",
      dataIndex: "services_experience_rate",
      key: "services_experience_rate",

      width: rem(200),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Technical enquires rate",
      dataIndex: "technical_enquiries_rate",
      key: "technical_enquiries_rate",

      width: rem(200),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Team Communication rate",
      dataIndex: "team_communication_rate",
      key: "team_communication_rate",

      width: rem(210),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Team help rate",
      dataIndex: "team_help_rate",
      key: "team_help_rate ",

      width: rem(180),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Product quality punctuality rate",
      dataIndex: "product_quality_punctuality_rate",
      key: "product_quality_punctuality_rate",

      width: rem(250),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Customer Satisfaction rate",
      dataIndex: "customer_statisfaction_rate",
      key: "customer_statisfaction_rate",

      width: rem(220),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Service provider rate",
      dataIndex: "service_provider_rate",
      key: "service_provider_rate",
      className: "tl",

      width: rem(240),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "About team product service",
      dataIndex: "about_team_product_service",
      key: "about_team_product_service",

      width: rem(240),
      className: "tl",

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Other feedback",
      dataIndex: "other_feedback",
      key: "other_feedback",
      className: "tl",

      width: rem(240),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "View",
      dataIndex: "pdf_id",
      key: "pdf_id",
      width: rem(100),
      render: (text, record) => (
        <div onClick={() => fetchFile(text)} style={{ cursor: "pointer" }}>
          <img
            src={view}
            alt="img"
            style={{ width: rem(24), height: rem(13) }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="feedbackdata table-wrap">
      {feedback && (
        <Table
          className="user-table"
          columns={columns}
          dataSource={
            feedback &&
            feedback.map((item, index) => ({
              ...item,
              key: index,
            }))
          }
          scroll={{
            y: `calc(100vh - ${rem(290)}`,
          }}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
            itemRender: (current, type, originalElement) => {
              if (type === "prev" || type === "next") {
                return (
                  <span className="pagination-button">{originalElement}</span>
                );
              }
              return originalElement;
            },
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            className: "paginate",
          }}
          // onRow={(record) => ({
          //   onClick: () => handleOpen(record),
          // })}
          loading={loading}
        />
      )}
      <Modal
        title="Docx Preview"
        className="feedback-pdf"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default FeedbackTable;
