// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #FCB02A;
  --primary-color-rgb: 252, 176, 42;
  --secondary-color: #757575;
  --secondary-color-rgb: 112, 112, 112;
  --body_font_color: #262626;
  --machine-alert: var(--primary-color);
  --machine-warning: #ff2600;
  --machine-success: #27c24c;
  --default-border: #dfdfdf;
  --default-border-rgb: 208, 208, 208;
  --body-bg: #f2f3f7;
  --static-white: #FFF;
}

.feedback-pdf .ant-modal-footer {
  display: none;
}

.feedback-pdf {
  top: 0.625rem;
  width: 42.5rem !important;
}
.feedback-pdf .ant-modal-body {
  height: 88vh;
  width: 100%;
}

.feedbackdata .ant-table-tbody tr {
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/Sass/Variable.scss","webpack://./src/Pages/Feedback/Feedback.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,iCAAA;EACA,0BAAA;EACA,oCAAA;EACA,0BAAA;EACA,qCAAA;EACA,0BAAA;EACA,0BAAA;EACA,yBAAA;EACA,mCAAA;EACA,kBAAA;EACA,oBAAA;ACCJ;;AAXA;EACE,aAAA;AAcF;;AAZA;EACE,aAAA;EACA,yBAAA;AAeF;AAbE;EACE,YAAA;EACA,WAAA;AAeJ;;AAZA;EACE,eAAA;AAeF","sourcesContent":[":root {\n    --primary-color: #FCB02A;\n    --primary-color-rgb: 252, 176, 42;\n    --secondary-color: #757575;\n    --secondary-color-rgb: 112, 112, 112;\n    --body_font_color: #262626;\n    --machine-alert: var(--primary-color);\n    --machine-warning: #ff2600;\n    --machine-success: #27c24c;\n    --default-border: #dfdfdf;\n    --default-border-rgb: 208, 208, 208;\n    --body-bg: #f2f3f7;\n    --static-white: #FFF;\n}\n\n\n// REM Calculations\n$baseFontSize: 16px;\n@function rem($pixels, $context: $baseFontSize) {\n  @if (unitless($pixels)) {\n    $pixels: $pixels * 1px;\n  }\n  @if (unitless($context)) {\n    $context: $context * 1px;\n  }\n  @return $pixels / $context * 1rem;\n}","@import \"../../Sass/Variable.scss\";\n\n.feedback-pdf .ant-modal-footer {\n  display: none;\n}\n.feedback-pdf {\n  top: rem(10);\n  width: rem(680) !important;\n\n  .ant-modal-body {\n    height: 88vh;\n    width: 100%;\n  }\n}\n.feedbackdata .ant-table-tbody tr {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
