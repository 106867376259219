import React from "react";

function Home() {
  return (
    <section className="d-flex align-items-center justify-content-center">
      Home
    </section>
  );
}

export default Home;
