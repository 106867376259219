import Procurement from "../Pages/Procurement/Procurement";
import Quality from "../Pages/Quality/Quality";
import QuoteGenerator from "../Pages/QuoteGenerator/QuoteGenerator";
import AwaitingOrder from "../Pages/SalesTracker/AwaitingOrder/AwaitingOrder";
import AwaitingQuotes from "../Pages/SalesTracker/AwaitingQuotes/AwaitingQuotes";
import DashBoard from "../Pages/SalesTracker/DashBoard/DashBoard";
import Report from "../Pages/SalesTracker/Report/Report";
import Enquires from "../Pages/SalesTracker/Enquires/Enquires";
import OrderPlaced from "../Pages/SalesTracker/OrderPlaced/OrderPlaced";
import User from "../Pages/User/Users/User";

const dynamicRoutes = [
  {
    path: "Salestracker/Dashboard",
    prop: "sales_tracker",
    component: <DashBoard />,
  },
  {
    path: "awaiting-quotes",
    prop: "sales_tracker",
    component: <AwaitingQuotes />,
  },
  {
    path: "awaiting-order",
    prop: "sales_tracker",
    component: <AwaitingOrder />,
  },
  {
    path: "order-placed",
    prop: "sales_tracker",
    component: <OrderPlaced />,
  },
  {
    path: "enquires",
    prop: "sales_tracker",
    component: <Enquires />,
  },
  {
    path: "Salestracker/report",
    prop: "sales_tracker",
    component: <Report />,
  },
  {
    path: "user",
    prop: "user_management",
    component: <User />,
  },
  {
    path: "QualityTeam.MTCgenerator",
    prop: "quality",
    component: <Quality />,
  },
  {
    path: "Procurement",
    prop: "procurement",
    component: <Procurement />,
  },
  {
    path: "QuoteGenerator",
    prop: "quote_generator",
    component: <QuoteGenerator />,
  },
];
export default dynamicRoutes;
