import React, { useMemo } from "react";
import { Table } from "antd";
import "./../../SalesTracker/table.scss";

const UserDataTable = ({
  handleOpen,
  users,
  filteredData,
  loading,
  renderEmptyOrValue,
}) => {
  const baseFontSize = 16;

  const rem = (pixels, context = baseFontSize) => {
    if (typeof pixels === "number") {
      pixels = pixels + "px";
    }

    if (typeof context === "number") {
      context = context + "px";
    }

    // Extract numeric values from pixel strings
    const pixelValue = parseFloat(pixels);
    const contextValue = parseFloat(context);

    return `${pixelValue / contextValue}rem`;
  };
  // Memoized filteredUsers to optimize performance
  const filteredUsers = useMemo(() => {
    return (users || [])
      .filter((user) => {
        // Filtering logic based on the provided conditions
        return (
          (user?.reference_number || "")
            .toLowerCase()
            .includes((filteredData?.reference_number || "").toLowerCase()) &&
          (user?.sales_person_name || "")
            .toLowerCase()
            .includes((filteredData?.sales_person_name || "").toLowerCase()) &&
          (user?.client_person_name || "")
            .toLowerCase()
            .includes((filteredData?.client_person_name || "").toLowerCase()) &&
          (user?.client_subject || "")
            .toLowerCase()
            .includes((filteredData?.client_subject || "").toLowerCase()) &&
          (filteredData.reminder_status1
            ? new Date(user.reminder_status) <=
              new Date(filteredData.reminder_status1)
            : true) &&
          (filteredData.last_login1
            ? new Date(user.reminder_status) >=
              new Date(filteredData.reminder_status2)
            : true)
        );
      })
      .map((user) => {
        // Adding orderClosureDays to the user object with formatted value
        const orderClosureDays = Math.floor(
          (new Date(user?.reminder_status) - new Date()) / (1000 * 60 * 60 * 24)
        );
        const formattedOrderClosureDays =
          orderClosureDays >= 7
            ? `${Math.floor(orderClosureDays / 7)} weeks`
            : `${orderClosureDays} days`;

        return {
          ...user,
          orderClosureDays: formattedOrderClosureDays,
        };
      });
  }, [users, filteredData]);

  // Columns configuration for Ant Design Table
  const columns = [
    {
      title: "Reference Number",
      dataIndex: "reference_number",
      key: "reference_number",
      width: rem(180),
      className: "tl",
      sorter: (a, b) => a.reference_number.localeCompare(b.reference_number),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Sales Person Name",
      dataIndex: "sales_person_name",
      key: "sales_person_name",
      className: "tl",
      sorter: (a, b) => a.sales_person_name.localeCompare(b.sales_person_name),
      width: rem(180),
      render: (text) => renderEmptyOrValue(text, true),
    },
    {
      title: "Status",
      dataIndex: "reminder_status",
      key: "reminder_status",
      sorter: (a, b) => a.reminder_status.localeCompare(b.reminder_status),
      width: rem(180),
      render: (text, record) => {
        // if (
        //   record.quotation_time !== ("Null" || "nan")
        // ) {
        //   console.log(record.currency_value === true);
        //   return <span className="op status">Order Placed</span>;
        // } else
        // if (record.quotation_time === true && record.ack_time !== "nan") {
        //   return <span className="awo status">Awaiting order</span>;
        // } else if (
        //   record.quotation_time === "Null" ||
        //   record.ack_time !== "nan"
        // ) {
        //   return <span className="aqo status">Awaiting Quote</span>;
        // } else {
        //   return "Empty";
        // }

        if (text === "order_placed") {
          return <span className="op status">Order Placed</span>;
        } else if (text === "success") {
          return <span className="awo status">Awaiting order</span>;
        } else if (text === "pending") {
          return <span className="aqo status">Awaiting Quote</span>;
        } else {
          return "Empty";
        }
      },
    },
    {
      title: "Quote Ageing",
      dataIndex: "client_email_time",
      key: "client_email_time",
      sorter: (a, b) => a.client_email_time.localeCompare(b.client_email_time),
      width: rem(180),
      render: (text) => {
        const quoteDate = new Date(text);
        const quoteAgeingDays = !isNaN(quoteDate)
          ? Math.floor((new Date() - quoteDate) / (1000 * 60 * 60 * 24))
          : null;

        return (
          <span>
            {quoteAgeingDays !== null ? (
              `${quoteAgeingDays} days`
            ) : (
              <span className="empty">Empty</span>
            )}
          </span>
        );
      },
    },
    {
      title: "Order Ageing",
      dataIndex: "sales_email_time",
      key: "sales_email_time",

      width: rem(180),
      render: (text, record) => {
        const orderDate = new Date(record.order_date_time);
        const quotationDate = new Date(record.quotation_time);

        const orderAgeingDays =
          !isNaN(orderDate) && !isNaN(quotationDate)
            ? Math.floor((orderDate - quotationDate) / (1000 * 60 * 60 * 24))
            : null;

        return (
          <span>
            {orderAgeingDays !== null ? (
              `${orderAgeingDays} days`
            ) : (
              <span className="empty">Empty</span>
            )}
          </span>
        );
      },
    },

    {
      title: "#of Follow-ups",
      dataIndex: "client_email",
      key: "client_email",
      sorter: (a, b) => a.client_email.localeCompare(b.client_email),
      width: rem(180),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Order Value",
      dataIndex: "total_order_value",
      key: "total_order_value",
      sorter: (a, b) => a.total_order_value.localeCompare(b.total_order_value),
      width: rem(180),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Order Closure Days",
      dataIndex: "order_closure_days",
      key: "order_closure_days",
      sorter: (a, b) =>
        a.order_closure_days.localeCompare(b.order_closure_days),
      width: rem(180),
      render: (text, record) => {
        const orderDate = new Date(record.order_date_time);
        const clientEmail = new Date(record.client_email_time);

        const orderClosureDays =
          !isNaN(orderDate) && !isNaN(clientEmail)
            ? Math.floor((orderDate - clientEmail) / (1000 * 60 * 60 * 24))
            : null;
        return (
          <span>
            {orderClosureDays !== null ? (
              `${orderClosureDays} days`
            ) : (
              <span className="empty">Empty</span>
            )}
          </span>
        );
      },
    },
  ];

  return (
    <div className="table-wrap">
      <Table
        className="user-table"
        columns={columns}
        dataSource={
          filteredUsers &&
          filteredUsers.map((item, index) => ({
            ...item,
            key: index,
          }))
        }
        scroll={{
          y: `calc(100vh - ${rem(290)}`,
        }}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          itemRender: (current, type, originalElement) => {
            if (type === "prev" || type === "next") {
              return (
                <span className="pagination-button">{originalElement}</span>
              );
            }
            return originalElement;
          },
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          className: "paginate",
        }}
        onRow={(record) => ({
          onClick: () => handleOpen(record),
        })}
        loading={loading}
      />{" "}
    </div>
  );
};

export default UserDataTable;
