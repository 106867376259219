import React from "react";
import notaccess from "../Assets/notaccess.svg";

const NotAccess = () => {
  return (
    <section>
      <div className="df notaccess">
        <img src={notaccess} alt="Not Access" style={{ height: "50%" }} />
      </div>
    </section>
  );
};

export default NotAccess;
