import React from "react";

const Procurement = () => {
  return (
    <section className="d-flex align-items-center justify-content-center">
      Procurement
    </section>
  );
};

export default Procurement;
