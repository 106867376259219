import React, { useEffect, useState } from "react";
import "./FeedbackForm.scss";
import { Table, Radio, Checkbox } from "antd";
import Button from "../../../Ui/Button/Button";
import SnackBar from "../../../Ui/SnackBar";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";

export default function FeedbackForm() {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [toast, setToast] = useState({
    submit: false,
    error: false,
    warning: false,
  });
  const [submit, setSubmit] = useState(false);

  const baseFontSize = 16;

  const rem = (pixels, context = baseFontSize) => {
    if (typeof pixels === "number") {
      pixels = pixels + "px";
    }

    if (typeof context === "number") {
      context = context + "px";
    }

    const pixelValue = parseFloat(pixels);
    const contextValue = parseFloat(context);

    return `${pixelValue / contextValue}rem`;
  };

  const [feedback, setFeedback] = useState({
    about_team_product_service: [],
    service_provider_rate: [],
  });
  const [customAlert, setCustomAlert] = useState({
    about_team_product_service: false,
    service_provider_rate: false,
  });
  const [cond, setCond] = useState(false);
  const [error, setError] = useState("");

  const handletoast = () => {
    setToast({ submit: false, error: false, warning: false });
  };

  const onSubmit = async (data) => {
    console.log("Form Data:", data);
    setCond(true);
    let hasErrors = false;

    if (feedback.about_team_product_service.length === 0) {
      setCustomAlert((prev) => ({ ...prev, about_team_product_service: true }));
      hasErrors = true;
    } else {
      setCustomAlert((prev) => ({
        ...prev,
        about_team_product_service: false,
      }));
    }

    if (feedback.service_provider_rate.length === 0) {
      setCustomAlert((prev) => ({ ...prev, service_provider_rate: true }));
      hasErrors = true;
    } else {
      setCustomAlert((prev) => ({ ...prev, service_provider_rate: false }));
    }

    if (hasErrors || Object.keys(errors).length > 0) {
      setToast({ ...toast, error: true });
      setError("Please fill all the required fields");
      return;
    }
    data.service_provider_rate = feedback.service_provider_rate;
    data.about_team_product_service = feedback.about_team_product_service;

    let feedbackdata = [
      "company_name",
      "client_name",
      "client_designation",
      "telephone_number",
      "email_address",
      "other_feedback",
      "quality_rate",
      "services_experience_rate",
      "technical_enquiries_rate",
      "team_communication_rate",
      "team_help_rate",
      "product_quality_punctuality_rate",
      "customer_satisfaction_rate",
      "service_provider_rate",
      "about_team_product_service",
    ];

    const dataKeys = Object.keys(data);

    const allKeysExist = dataKeys.every((key) => feedbackdata.includes(key));

    if (!allKeysExist) {
      return;
    }

    try {
      setSubmit(true);
      await axios.post(`https://autodesk.dtskill.com/feedback_data`, data);

      setFeedback({
        about_team_product_service: [],
        service_provider_rate: [],
      });
      reset({
        company_name: "",
        client_name: "",
        client_designation: "",
        telephone_number: "",
        email_address: "",
        other_feedback: "",
        quality_rate: "",
        services_experience_rate: "",
        technical_enquiries_rate: "",
        team_communication_rate: "",
        team_help_rate: "",
        product_quality_punctuality_rate: "",
        customer_satisfaction_rate: "",
      });
      setToast({ submit: true });
      setError("Successfully submitted");
    } catch (error) {
      setToast({ error: true });
      setError("Submission failed");
      console.log(error.msg);
    }

    setSubmit(false);
    setCond(false);
  };
  useEffect(() => {
    if (feedback.about_team_product_service.length === 0 && cond) {
      setCustomAlert((prev) => ({ ...prev, about_team_product_service: true }));
    } else {
      setCustomAlert((prev) => ({
        ...prev,
        about_team_product_service: false,
      }));
    }

    if (feedback.service_provider_rate.length === 0 && cond) {
      setCustomAlert((prev) => ({ ...prev, service_provider_rate: true }));
    } else {
      setCustomAlert((prev) => ({ ...prev, service_provider_rate: false }));
    }
  }, [feedback.service_provider_rate, feedback.about_team_product_service]);

  const checkvalue1 = [
    { key: "1", value: "Quality", pass: "service_provider_rate" },
    { key: "2", value: "Trust", pass: "service_provider_rate" },
    { key: "3", value: "Brand Name", pass: "service_provider_rate" },
    { key: "4", value: "Previous experience", pass: "service_provider_rate" },
    { key: "5", value: "Word of mouth", pass: "service_provider_rate" },
  ];

  const checkvalue2 = [
    {
      key: "1",
      value: "Customer Satisfaction",
      pass: "about_team_product_service",
    },
    { key: "2", value: "Response Time", pass: "about_team_product_service" },
    {
      key: "3",
      value: "Quality of product",
      pass: "about_team_product_service",
    },
    {
      key: "4",
      value: "Customer Engagement",
      pass: "about_team_product_service",
    },
    {
      key: "5",
      value: "Problem Resolution",
      pass: "about_team_product_service",
    },
  ];

  const columns = [
    { title: " ", dataIndex: "question", key: "question" },
    {
      title: "Very Good",
      dataIndex: "veryGood",
      key: "veryGood",
      width: rem(118),
    },
    { title: "Good", dataIndex: "good", key: "good", width: rem(100) },
    { title: "Average", dataIndex: "average", key: "average", width: rem(100) },
    { title: "Poor", dataIndex: "poor", key: "poor", width: rem(100) },
  ];

  function checkboxHandler(e, pass) {
    let isSelected = e.target.checked;
    let value = e.target.value;

    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [pass]: isSelected
        ? [...prevFeedback[pass], value]
        : prevFeedback[pass].filter((item) => item !== value),
    }));
  }

  const renderRadio = (question, value) => (
    <Controller
      control={control}
      name={question}
      rules={{ required: "This field is required" }}
      render={({ field }) => (
        <Radio {...field} checked={field.value === value} value={value} />
      )}
    />
  );

  const dataSource = [
    {
      key: "1",
      question: "How would you rate our product in terms of Quality?",
      veryGood: renderRadio("quality_rate", "Very Good"),
      good: renderRadio("quality_rate", "Good"),
      average: renderRadio("quality_rate", "Average"),
      poor: renderRadio("quality_rate", "Poor"),
    },
    {
      key: "2",
      question:
        "How would you rate our products and services based on your experience?",
      veryGood: renderRadio("services_experience_rate", "Very Good"),
      good: renderRadio("services_experience_rate", "Good"),
      average: renderRadio("services_experience_rate", "Average"),
      poor: renderRadio("services_experience_rate", "Poor"),
    },
    {
      key: "3",
      question:
        "How would you rate our team on their ability to resolve your technical enquiries?",
      veryGood: renderRadio("technical_enquiries_rate", "Very Good"),
      good: renderRadio("technical_enquiries_rate", "Good"),
      average: renderRadio("technical_enquiries_rate", "Average"),
      poor: renderRadio("technical_enquiries_rate", "Poor"),
    },
    {
      key: "4",
      question: "How would you rate our team’s communication?",
      veryGood: renderRadio("team_communication_rate", "Very Good"),
      good: renderRadio("team_communication_rate", "Good"),
      average: renderRadio("team_communication_rate", "Average"),
      poor: renderRadio("team_communication_rate", "Poor"),
    },
    {
      key: "5",
      question: "How would you rate our team’s willingness to help?",
      veryGood: renderRadio("team_help_rate", "Very Good"),
      good: renderRadio("team_help_rate", "Good"),
      average: renderRadio("team_help_rate", "Average"),
      poor: renderRadio("team_help_rate", "Poor"),
    },
    {
      key: "6",
      question:
        "How would you rate our company’s delivery services in terms of product quality, punctuality and problem?",
      veryGood: renderRadio("product_quality_punctuality_rate", "Very Good"),
      good: renderRadio("product_quality_punctuality_rate", "Good"),
      average: renderRadio("product_quality_punctuality_rate", "Average"),
      poor: renderRadio("product_quality_punctuality_rate", "Poor"),
    },
    {
      key: "7",
      question: "How would you rate your overall customer satisfaction?",
      veryGood: renderRadio("customer_satisfaction_rate", "Very Good"),
      good: renderRadio("customer_satisfaction_rate", "Good"),
      average: renderRadio("customer_satisfaction_rate", "Average"),
      poor: renderRadio("customer_satisfaction_rate", "Poor"),
    },
  ];

  return (
    <section style={{ height: "100%" }}>
      <div className="feedbackform d-flex flex-column justify-content-lg-start align-items-center m-auto h-100 ">
        <div className="header d-flex flex-column justify-content-center align-items-center ">
          <div className="heading  ">Customer Feedback Form</div>
          <p className="heading-info">
            As part of our (ISO 9001-2015) Quality Management System, and our
            commitment to continuously improve the quality of our services and
            products. We would appreciate your comments and suggestions. Please
            take a few minutes to complete the short questionnaire below. We
            thank you for your time and input.
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-100">
          <div className="Customer-info w-100 ">
            <div className="heading-2">Customer Information/Details</div>
            <hr className="line" />
            <div className="input-fields  w-100">
              <div className="inputs-1 d-flex flex-wrap align-items-center ">
                <div className="info-input">
                  <span className="sub-head">Company Name:</span>
                  <input
                    type="text"
                    className="input-filter"
                    {...register("company_name", { required: true })}
                  />
                  {errors.company_name && (
                    <span className="FormError">This field is required</span>
                  )}
                </div>
                <div className="info-input">
                  <span className="sub-head">Client Name:</span>
                  <input
                    type="text"
                    className="input-filter"
                    {...register("client_name", { required: true })}
                  />
                  {errors.client_name && (
                    <span className="FormError">This field is required</span>
                  )}
                </div>
                <div className="info-input">
                  <span className="sub-head">Client Designation:</span>
                  <input
                    type="text"
                    className="input-filter"
                    {...register("client_designation", { required: true })}
                  />
                  {errors.client_designation && (
                    <span className="FormError">This field is required</span>
                  )}
                </div>
                <div className="info-input">
                  <span className="sub-head">Telephone Number:</span>
                  <input
                    type="text" // Changed from 'number' to 'text'
                    className="input-filter"
                    inputMode="numeric" // Ensures the numeric keypad is shown on mobile devices
                    pattern="\d*" // Restricts the input to numeric values only
                    {...register("telephone_number", {
                      required: true,
                      pattern: /^\d{10}$/,
                    })}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes any non-numeric characters
                    }}
                  />
                  {errors.telephone_number && (
                    <span className="FormError">
                      {errors.telephone_number.type === "required"
                        ? "This field is required"
                        : "Invalid mobile number"}
                    </span>
                  )}
                </div>

                <div className="info-input">
                  <span className="sub-head">Email Address:</span>
                  <input
                    type="email"
                    className="input-filter"
                    {...register("email_address", {
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    })}
                  />
                  {errors.email_address && (
                    <span className="FormError">
                      {errors.email_address.type === "required"
                        ? "This field is required"
                        : "Invalid email address"}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-100">
            <div className="heading-2">
              How satisfied are you with the following{" "}
              {(errors.quality_rate ||
                errors.services_experience_rate ||
                errors.technical_enquiries_rate ||
                errors.team_communication_rate ||
                errors.team_help_rate ||
                errors.product_quality_punctuality_rate ||
                errors.customer_satisfaction_rate) && (
                <span className="FormError">Answer all the fields</span>
              )}
            </div>
            <hr className="line" />
            <div className="feedback-table">
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
            </div>
          </div>
          <div className="w-100">
            <div className="heading-2">
              Please mark the appropriate answer for each question{" "}
            </div>
            <hr className="line" />
            <div className="sub-head-2">
              Why did you choose us as your service provider?
              {
                // feedback.service_provider_rate.length === 0
                customAlert.service_provider_rate && (
                  <span className="FormError">This field is required</span>
                )
              }
            </div>
            <div className="input-fields w-100 ">
              <div className="inputs-1 d-flex flex-wrap align-items-center ">
                {checkvalue1.map((item) => (
                  <div key={item.key} className="input-checks">
                    <span className="sub-head">{item.value}</span>
                    <Checkbox
                      checked={feedback[item.pass].includes(item.value)}
                      onChange={(e) => checkboxHandler(e, item.pass)}
                      value={item.value}
                    />
                  </div>
                ))}
              </div>

              <div className="sub-head-2">
                What did you like best about our team/products/services?
                {
                  // feedback.about_team_product_service.length === 0
                  customAlert.about_team_product_service && (
                    <span className="FormError">This field is required</span>
                  )
                }
              </div>

              <div className="inputs-1 d-flex flex-wrap align-items-center ">
                {checkvalue2.map((item) => (
                  <div key={item.key} className="input-checks">
                    <span className="sub-head">{item.value}</span>
                    <Checkbox
                      checked={feedback[item.pass].includes(item.value)}
                      onChange={(e) => checkboxHandler(e, item.pass)}
                      value={item.value}
                    />
                  </div>
                ))}
              </div>

              <div className="sub-head-2">
                Any other feedback you can give us would be beneficial?
              </div>

              <div className="inputs-1 d-flex flex-wrap align-items-center ">
                <div className="other-feedback">
                  <input
                    className="input-filter"
                    {...register("other_feedback")}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center ">
            <Button
              btnname={submit ? "Submitting..." : "Submit"}
              height={"40px"}
              onclick={onSubmit}
              type="submit"
            />
          </div>
        </form>
      </div>

      <SnackBar
        message={error}
        severity={"error"}
        handleClose={handletoast}
        Open={toast.error}
      />
      <SnackBar
        message={error}
        severity={"success"}
        handleClose={handletoast}
        Open={toast.submit}
      />
      <SnackBar
        message={error}
        severity={"warning"}
        handleClose={handletoast}
        Open={toast.warning}
      />
    </section>
  );
}
