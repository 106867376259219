// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #FCB02A;
  --primary-color-rgb: 252, 176, 42;
  --secondary-color: #757575;
  --secondary-color-rgb: 112, 112, 112;
  --body_font_color: #262626;
  --machine-alert: var(--primary-color);
  --machine-warning: #ff2600;
  --machine-success: #27c24c;
  --default-border: #dfdfdf;
  --default-border-rgb: 208, 208, 208;
  --body-bg: #f2f3f7;
  --static-white: #FFF;
}

.footer-color {
  color: #e9e9e9;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3rem;
}

.footer {
  justify-content: center;
  gap: 12.1875rem;
  height: inherit;
}

.footer1 {
  gap: 1.25rem;
}

footer {
  height: 3.375rem;
  width: 100vw;
  background-color: #000;
}`, "",{"version":3,"sources":["webpack://./src/Sass/Variable.scss","webpack://./src/Components/Footer/Footer.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,iCAAA;EACA,0BAAA;EACA,oCAAA;EACA,0BAAA;EACA,qCAAA;EACA,0BAAA;EACA,0BAAA;EACA,yBAAA;EACA,mCAAA;EACA,kBAAA;EACA,oBAAA;ACCJ;;AAZA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAeF;;AAZA;EACE,uBAAA;EACA,eAAA;EACA,eAAA;AAeF;;AAZA;EACE,YAAA;AAeF;;AAZA;EACE,gBAAA;EACA,YAAA;EACA,sBAAA;AAeF","sourcesContent":[":root {\n    --primary-color: #FCB02A;\n    --primary-color-rgb: 252, 176, 42;\n    --secondary-color: #757575;\n    --secondary-color-rgb: 112, 112, 112;\n    --body_font_color: #262626;\n    --machine-alert: var(--primary-color);\n    --machine-warning: #ff2600;\n    --machine-success: #27c24c;\n    --default-border: #dfdfdf;\n    --default-border-rgb: 208, 208, 208;\n    --body-bg: #f2f3f7;\n    --static-white: #FFF;\n}\n\n\n// REM Calculations\n$baseFontSize: 16px;\n@function rem($pixels, $context: $baseFontSize) {\n  @if (unitless($pixels)) {\n    $pixels: $pixels * 1px;\n  }\n  @if (unitless($context)) {\n    $context: $context * 1px;\n  }\n  @return $pixels / $context * 1rem;\n}","@import \"../../Sass/Variable.scss\";\n.footer-color {\n  color: #e9e9e9;\n  text-align: center;\n  font-size: rem(16);\n  font-style: normal;\n  font-weight: 400;\n  line-height: rem(20.8);\n}\n\n.footer {\n  justify-content: center;\n  gap: rem(195);\n  height: inherit;\n}\n\n.footer1 {\n  gap: rem(20);\n}\n\nfooter {\n  height: rem(54);\n  width: 100vw;\n  background-color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
