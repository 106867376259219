// ProfileDropdown.jsx
import React from "react";
import "./ProfileDropdown.scss";
import profile from "./../../Assets/NavbarIcon/Profile1.svg";
import settings from "./../../Assets/NavbarIcon/Settings.svg";
import logout from "./../../Assets/NavbarIcon/Logout.svg";
import Help from "./../../Assets/NavbarIcon/Help.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserdata } from "../../Redux/Reducers";
// ProfileDropdown component that displays a dropdown with profile-related options
const ProfileDropdown = () => {
  // Hook to navigate between pages
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Function to handle logout and clear session storage
  const handleLogout = () => {
    sessionStorage.removeItem("jwtToken");
    sessionStorage.removeItem("key");
    sessionStorage.removeItem("Name");
    // sessionStorage.removeItem("Email");
    sessionStorage.removeItem("access");
    sessionStorage.removeItem("dropdownState");
    sessionStorage.removeItem("Role");
    sessionStorage.removeItem("UserName");
    dispatch(setUserdata(""));
    navigate("/Login");
  };

  return (
    <div className="profile-drop">
      {/* Profile-related options */}
      <div className="pd1">
        <div className="pdc">
          <img src={profile} alt="icon" />
          <span>Profile</span>
        </div>
        <div className="pdc">
          <img src={settings} alt="icon" />
          <span>Settings</span>
        </div>{" "}
        <div className="pdc">
          <img src={Help} alt="icon" />
          <span>Help Center</span>
        </div>
      </div>

      {/* Logout option */}
      <div className="pd2">
        <div className="pdc" onClick={handleLogout}>
          <img src={logout} alt="icon" />
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropdown;
