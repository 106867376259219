import React, { useState } from "react";
import "./STeditPopup.scss";
import { Modal } from "antd";
import Attached from "./../../../../Assets/PopIcon/Attached.svg";
import Document from "./../../../../Assets/PopIcon/Document.svg";
import Email from "./../../../../Assets/PopIcon/Email.svg";
import Pricetag from "./../../../../Assets/PopIcon/Price tag.svg";
import Recruitment from "./../../../../Assets/PopIcon/Recruitment.svg";
// import { Button } from "antd/es/radio";
import Button from "../../../../Ui/Button/Button";

const EditPopup = ({ user, handleClose, open, handleDelete, handleUpdate }) => {
  // State to manage user data in the form
  const [userData, setUserData] = useState({
    reference_number: user.reference_number,
    sales_person_name: user.sales_person_name,
    sales_mail: user.sales_mail,
    client_person_name: user.client_person_name,
    client_email: user.client_email,
    ack_time: user.ack_time,
    quotation_time: user.quotation_time,
    client_cc: user.client_cc,
    client_subject: user.client_subject,
    sales_email_time: user.sales_email_time,
    client_email_time: user.client_email_time,
  });
  // Function to handle the update button click
  const handleUpdateClick = () => {
    const emailname = sessionStorage.getItem("Name");
    // Prepare updated data
    const updatedData = {
      reference_number: userData.reference_number,
      sales_person_name: userData.sales_person_name,
      sales_mail: userData.sales_mail,
      client_person_name: userData.client_person_name,
      client_email: userData.client_email,
      // ack_time: userData.ack_time,
      // quotation_time: userData.quotation_time,
      client_cc: userData.client_cc,
      client_subject: userData.client_subject,
      client_email_time: userData.client_email_time,
      sales_email_time: userData.sales_email_time,
    };
    // Call the handleUpdate function
    handleUpdate(user.reference_number, emailname, updatedData);
  };
  // Function to handle the delete button click
  const handleDeleteClick = () => {
    handleDelete(user.reference_number, user.sales_mail);
  };
  const baseFontSize = 16;

  const rem = (pixels, context = baseFontSize) => {
    if (typeof pixels === "number") {
      pixels = pixels + "px";
    }

    if (typeof context === "number") {
      context = context + "px";
    }

    // Extract numeric values from pixel strings
    const pixelValue = parseFloat(pixels);
    const contextValue = parseFloat(context);

    return `${pixelValue / contextValue}rem`;
  };
  return (
    <>
      <Modal
        title={" "}
        centered
        open={open}
        // onOk={() => setOpen(true)}
        onCancel={handleClose}
        width={rem(1000)}
        className="edit-popup"
      >
        <div className="pop" style={{ display: "flex" }}>
          <div className="popup-left">
            <span className="actions">
              <span>
                <img src={Recruitment} alt="icon" />
                <p>New Lead</p>
              </span>
              <span>
                <img src={Attached} alt="icon" />
                <p>Attachments</p>
              </span>
              <span>
                <img src={Document} alt="icon" />
                <p>Checklists</p>
              </span>
              <span>
                <img src={Document} alt="icon" />
                <p>Pdf</p>
              </span>
              <span>
                <img src={Email} alt="icon" />
                <p>Email</p>
              </span>
              <span>
                <img src={Pricetag} alt="icon" />
                <p>Sales Pipeline</p>
              </span>
            </span>
            {/* Input fields for user details */}
            <div>
              <p>Internal Refrence Number </p>
              <input
                value={userData.reference_number}
                onChange={(e) =>
                  setUserData({ ...userData, reference_number: e.target.value })
                }
              />
            </div>{" "}
            <div>
              <p>Sales Person Name</p>
              <input
                value={userData.sales_person_name}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    sales_person_name: e.target.value,
                  })
                }
              />
            </div>{" "}
            <div>
              <p>Sales Person Mail</p>
              <input
                value={userData.sales_mail}
                onChange={(e) =>
                  setUserData({ ...userData, sales_mail: e.target.value })
                }
              />
            </div>
            {/* <div>
              <p>CRM Email Receive Time</p>
              <input
                value={userData.sales_mail}
                onChange={(e) =>
                  setUserData({ ...userData, sales_mail: e.target.value })
                }
              />
            </div> */}
            <div>
              <p>Client Name</p>
              <input
                value={userData.client_person_name}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    client_person_name: e.target.value,
                  })
                }
              />
            </div>{" "}
            <div>
              <p>Client Email</p>
              <input
                value={userData.client_email}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    client_email: e.target.value,
                  })
                }
              />
            </div>{" "}
            <div>
              <p>Client CC</p>
              <input
                value={userData.client_cc}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    client_cc: e.target.value,
                  })
                }
              />
            </div>{" "}
            <div>
              <p>Subject</p>
              <input
                value={userData.client_subject}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    client_subject: e.target.value,
                  })
                }
              />
            </div>
            {/* <div>
              <p>Original Email Receive Time</p>
              <input value={userData.sales_email_time.slice(5, -6)} />
            </div> */}
          </div>
          <hr />
          <div className="popup-right">
            {/* Right panel input fields */}
            <div>
              <p>Client email Time</p>
              <input
                value={userData.client_email_time}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    client_email_time: e.target.value,
                  })
                }
                className="popup-right-input"
              />
            </div>{" "}
            <div>
              <p>Sales Email Time</p>
              <input
                value={userData.sales_email_time}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    sales_email_time: e.target.value,
                  })
                }
                className="popup-right-input"
              />
            </div>
            <div>
              <p>Ack Email Time</p>
              <input
                value={userData.ack_time}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    ack_time: e.target.value,
                  })
                }
                className="popup-right-input"
              />
            </div>
            <div>
              <p>Quotation receive Time</p>
              <input
                value={userData.quotation_time}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    quotation_time: e.target.value,
                  })
                }
                className="popup-right-input"
              />
            </div>
            <div>
              <span className="df api-btn">
                <Button btnname={"Delete"} onclick={handleDeleteClick} />
                <Button onclick={handleUpdateClick} btnname={"Update"} />
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditPopup;
