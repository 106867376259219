import React, { useState } from "react";
import "./FilterEnquires.scss";
import Button from "../../../../Ui/Button/Button";
const FilterEnquires = ({ onFilter, hide }) => {
  // State to manage filtered data
  const [filteredData, setFilteredData] = useState({
    reference_number: "",
    sales_person_name: "",
    client_person_name: "",
    client_subject: "",
    reminder_status1: "",
    reminder_status2: "",
  });

  // Function to handle filter application
  const handleFilter = () => {
    onFilter(filteredData);
    hide();
    console.log(filteredData);
  };
  // Function to reset filters
  const ResetFilter = () => {
    // Reset filtered data
    const resetData = {
      reference_number: "",
      sales_person_name: "",
      client_person_name: "",
      client_subject: "",
      reminder_status1: "",
      reminder_status2: "",
    };

    setFilteredData(resetData);

    // Apply reset data to clear filters
    onFilter(resetData);

    hide();
  };

  return (
    <div className="filter-box">
      <div className="popover-title">Filter</div>

      <div className="filter-body">
        {/* Filter input fields */}
        <div className="filter-Sub">Ref Number</div>
        <input
          type="text"
          className="input-filter"
          value={filteredData?.reference_number || ""}
          onChange={(e) => {
            setFilteredData({
              ...filteredData,
              reference_number: e.target.value,
            });
          }}
        />
        <div className="filter-Sub">Sales Person</div>
        <input
          type="text"
          className="input-filter"
          value={filteredData?.sales_person_name || ""}
          onChange={(e) => {
            setFilteredData({
              ...filteredData,
              sales_person_name: e.target.value,
            });
          }}
        />
        <div className="filter-Sub">Client Name</div>
        <input
          type="text"
          className="input-filter"
          value={filteredData?.client_person_name || ""}
          onChange={(e) => {
            setFilteredData({
              ...filteredData,
              client_person_name: e.target.value,
            });
          }}
        />
        <div className="filter-Sub">Subject</div>
        <input
          type="text"
          className="input-filter"
          value={filteredData?.client_subject || ""}
          onChange={(e) => {
            setFilteredData({
              ...filteredData,
              client_subject: e.target.value,
            });
          }}
        />
        {/* <div className="filter-Sub">Due Date</div>
        <div className="date-filter">
          <div className="date-f1">
            <div className="sub-date">From</div>
            <input
              type="date"
              className="input-filter"
              value={filteredData?.reminder_status1 || ""}
              onChange={(e) => {
                setFilteredData({
                  ...filteredData,
                  reminder_status1: e.target.value,
                });
              }}
            />
          </div>
          <div className="date-f1">
            <div className="sub-date">To</div>
            <input
              type="date"
              className="input-filter"
              value={filteredData?.reminder_status2 || ""}
              onChange={(e) => {
                setFilteredData({
                  ...filteredData,
                  reminder_status2: e.target.value,
                });
              }}
            />
          </div>
        </div> */}
      </div>
      {/* Filter buttons */}
      <div className="filter-btn">
        <div>
          <Button
            btnname={"Reset"}
            bg={"#fff"}
            cl={"#000"}
            border={"1px solid #1E6641"}
            onclick={ResetFilter}
          />
        </div>
        <div>
          <Button btnname={"Apply Now"} onclick={handleFilter} />
        </div>
      </div>
    </div>
  );
};

export default FilterEnquires;
