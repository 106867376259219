import React from "react";
import SalesTracker from "../STcomponents/SalesTracker/SalesTracker";
import AQTable from "./AQTable";
const AwaitingQuotes = () => {
  return (
    <>
      <SalesTracker
        title={"Awaiting Quotes"}
        UserDataTable={AQTable}
        api={"https://spira.admin.dtskill.com/api/waiting_quote"}
      />
    </>
  );
};

export default AwaitingQuotes;
