// Importing necessary dependencies
import React from "react";
import { Navigate } from "react-router-dom";
import MainHome from "../../Pages/MainHome/MainHome";

// Custom hook to check if the user is authenticated
const useAuth = () => {
  // Check if jwtToken is present in sessionStorage to determine if the user is logged in
  const user = {
    loggedIn: sessionStorage.getItem("jwtToken") === null,
  };

  return user && user.loggedIn;
};

// Functional component for handling protected routes
const ProtectedRoutes = () => {
  // Check if the user is authenticated
  const isAuth = useAuth();

  // Redirect to Login page if not authenticated, otherwise render the MainHome component
  return isAuth ? <Navigate to="/Login" /> : <MainHome />;
};

// Exporting the ProtectedRoutes component
export { ProtectedRoutes };
