// Register.jsx
import React, { useState } from "react";
import _nonAuthHttp from "../../../Utils/Api/_nonAuthHttp";
import { useNavigate } from "react-router-dom";
import LoginImage from "./../../../Assets/LoginPage/Login-image.svg";
import spiraLogo from "./../../../Assets/LoginPage/spira 1.svg";
import { Link } from "react-router-dom";
import Button from "../../../Ui/Button/Button";
import SnackBar from "../../../Ui/SnackBar";

export default function Login() {
  // State variables for managing form data, SnackBar visibility, navigation, loading state, and errors
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    role_name: "",
    createdDate: `${new Date().toISOString()}`,
    reporting_to: "",
  });
  const [open, setOpen] = useState({ submit: false, error: false });
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [errors, setErrors] = useState("");

  // Event handler for input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to close the SnackBar
  const handleClose = (state) => {
    setOpen({ ...state, open: false });
  };

  // Function to validate registration input
  const validateLoginInput = (username, password) => {
    if (username.length === 0 || password.length === 0) {
      return "Please fill out the fields";
    }
    return null;
  };

  // Function to handle the registration process
  const handleRegister = async (e) => {
    setloading(true);
    e.preventDefault();

    // Validate registration input
    const inputError = validateLoginInput(formData.username, formData.password);

    if (inputError) {
      setOpen({ error: true });
      setErrors(inputError);
      setloading(false);
      return;
    }

    try {
      // Make a registration request using _nonAuthHttp
      const res = await _nonAuthHttp.post("/Register", formData);

      if (res.status === 201) {
        // Handle successful registration
        setOpen({ submit: true });
        setErrors("Successfully registered! Please login.");
        navigate("/Login");
      } else {
        // Handle registration error
        setOpen({ error: true });
        setErrors(res.data);
      }
    } catch (error) {
      // Handle registration error exceptions
      setOpen({ error: true });
      if (error.response && error.response.status === 500) {
        setErrors("Internal server error ");
      } else if (error.response && error.response.status === 400) {
        setErrors("User already exists");
      } else {
        setErrors(error.message);
        console.error("Error during registration:", error);
      }
    } finally {
      setloading(false);
    }
  };

  // JSX for the registration component
  return (
    <div className="newLogin flexBox">
      <div className="left flexBox">
        <img src={LoginImage} alt="CompanyLogo"></img>
      </div>
      <div className={`right flexBox`}>
        <div>
          <h1>Join the Power Revolution: Register with Spira Power today!</h1>
          <div className={`login-card`}>
            <div className="login-logo login-title">
              <img src={spiraLogo} alt="img" />
            </div>

            <form className="login-card-form">
              {/* Input fields for email, username, and password */}

              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Johndoe@spirapower.com"
                value={formData.email}
                onChange={handleChange}
                className="txtBox"
                required
              />

              <label htmlFor="username">Username</label>
              <input
                type="text"
                name="username"
                id="username"
                placeholder="Johndoe"
                value={formData.username}
                onChange={handleChange}
                className="txtBox"
                required
              />

              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="*********"
                onChange={handleChange}
                className="txtBox"
                value={formData.password}
                autoComplete="on"
                required
              />

              {/* SnackBar components for displaying errors and success messages */}
              <SnackBar
                message={errors}
                severity={"error"}
                handleClose={handleClose}
                Open={open.error}
              />
              <SnackBar
                message={errors}
                severity={"success"}
                handleClose={handleClose}
                Open={open.submit}
              />

              <div className="login-btn">
                {/* Button component for registering, with loading state handling */}
                <Button
                  btnname={loading ? "CREATING ACCOUNT..." : "CREATE ACCOUNT"}
                  onclick={handleRegister}
                  width={"100%"}
                  height={50}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleRegister();
                    }
                  }}
                />
                {/* Link to navigate to the login page */}
                <div className="login-title dont-have-acc ">
                  Already have an account?
                  {""}
                  <Link className="subtitle" to="/Login">
                    Login Spira Power
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
