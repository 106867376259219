import React from "react";
import "./dashboard.css";
const DashBoard = () => {
  return (
    <div className="d-flex align-items-center justify-content-center w-100 ">
      DashBoard
    </div>
  );
};

export default DashBoard;
