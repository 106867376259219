import React, { useEffect, useState } from "react";
import "./UserPopup.scss"; // Custom styles for the component
import "../../SalesTracker/STcomponents/STeditPopup/STeditPopup.scss";
import { Modal } from "antd"; // Ant Design Modal component
import _http from "./../../../Utils/Api/_http"; // Custom HTTP utility
import Button from "../../../Ui/Button/Button"; // Custom Button component
import SnackBar from "../../../Ui/SnackBar"; // Custom Snackbar component

// Functional component for user registration popup
const UserPopup = ({
  open, // Indicates whether the popup is open or not
  handleClose, // Function to close the popup
  fetchData, // Function to fetch data after user registration
  roles, // User roles
  userList, // List of users
  names, // User names
  headers, //token for api header
}) => {
  // State to manage form data for user registration
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    role_name: "",
    createdDate: `${new Date().toISOString()}`,
    reporting_to: "",
  });

  // State to manage toast notifications for success and error
  const [toast, setToast] = useState({ submit: false, error: false });

  // State to manage error messages
  const [errors, setError] = useState("");

  // Function to handle closing toast notifications
  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const baseFontSize = 16;

  const rem = (pixels, context = baseFontSize) => {
    if (typeof pixels === "number") {
      pixels = pixels + "px";
    }

    if (typeof context === "number") {
      context = context + "px";
    }

    // Extract numeric values from pixel strings
    const pixelValue = parseFloat(pixels);
    const contextValue = parseFloat(context);

    return `${pixelValue / contextValue}rem`;
  };

  // Function to determine user roles based on the role of the current user
  const [Roles, setRoles] = useState([]);
  const RoleType = () => {
    if (roles === "admin") {
      setRoles(["Manager", "Teamlead", "employee"]);
    } else if (roles === "Manager") {
      setRoles(["Teamlead", "employee"]);
    } else if (roles === "Teamlead") {
      setRoles(["employee"]);
    } else {
      return;
    }
  };

  const [reportingTo, setReportingTo] = useState([]);
  // Function to get reporting to options based on user roles
  const getReportingToOptions = (role_names, names) => {
    let reportingToOptions = [];

    if (role_names === "Manager") {
      reportingToOptions = [names];
    } else if (role_names === "Teamlead") {
      const managers = userList
        .filter((user) => user.role_name === "Manager")
        .map((user) => user.user);
      reportingToOptions = managers.length > 0 ? managers : [names];
    } else if (role_names === "employee") {
      const teamleads = userList
        .filter((user) => user.role_name === "Teamlead")
        .map((user) => user.user);
      reportingToOptions = teamleads.length > 0 ? teamleads : [names];
    }

    setReportingTo(reportingToOptions);
  };

  // Effect to update reporting to options when user roles or names change
  useEffect(() => {
    RoleType();
    getReportingToOptions(formData.role_name, names);
  }, [roles, formData.role_name]);

  // Function to create options for user roles
  const roletype = () =>
    Roles.map((role) => (
      <option key={role} value={role}>
        {role}
      </option>
    ));

  // Function to handle form submission for user registration
  const handleSubmit = async (e) => {
    // Validation checks for required fields and email format
    if (
      !formData.username ||
      !formData.email ||
      !formData.role_name ||
      !formData.reporting_to ||
      !formData.password
    ) {
      setToast({ error: true });
      setError("Please fill all the input fields");
    } else if (
      !/^([a-zA-Z0-9_.]+@[a-zA-Z0-9_]+\.[a-zA-Z]{2,})$/.test(formData.email)
    ) {
      setToast({ error: true });
      setError("Wrong email format");
    } else {
      try {
        // Making a POST request to register the user
        const res = await _http.post("/Register", formData, { headers });

        // Handling response based on status
        if (res.status === 201) {
          setToast({ submit: true });
          setError("Successfully registered! Please login.");
        } else {
          setToast({ error: true });
          setError(res.data);
        }

        // Fetching data after successful registration
        fetchData(formData);

        // Resetting the form data and closing the popup
        setFormData({
          username: "",
          password: "",
          email: "",
          role_name: "",
          reporting_to: "",
        });
        handleClose();
      } catch (error) {
        // Handling errors and displaying appropriate messages
        setToast({ error: true });
        if (error.response.status === 400) {
          setError(error.response.data);
        } else {
          setError(error.message);
        }
      }
    }
  };

  return (
    <>
      <Modal
        title={" "} // Empty title
        centered // Centered modal
        open={open} // Open state of the modal
        onCancel={handleClose} // Function to handle modal close
        width={rem(984)} // Width of the modal
        className="edit-popup" // Custom class for styling
      >
        <div className="user-popup ">
          <div className="user-popup-left df">
            <div className="">
              <span>Full Name</span>
              <input
                placeholder="Roger Levin"
                value={formData.username}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    username: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <span>Email</span>
              <input
                placeholder="roger_levin@learnasyougo.org"
                value={formData.email}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  })
                }
              />
            </div>{" "}
            <div>
              <span>User Role</span>
              <select
                className="form-select"
                aria-label="Default select example"
                placeholder="Select user type"
                value={formData.role_name}
                style={{ textTransform: "capitalize" }}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    role_name: e.target.value,
                  })
                }
              >
                <option value={""}>Select User Role</option>
                {roletype()}
              </select>
            </div>{" "}
          </div>
          <div className="user-popup-right df">
            <div>
              <span>Reporting To</span>

              <select
                className="form-select"
                aria-label="Default select example"
                placeholder="Select user type"
                value={formData.reporting_to}
                style={{ textTransform: "capitalize" }}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    reporting_to: e.target.value,
                  })
                }
              >
                {formData.role_name ? (
                  <option value={""}>Select Reporting to</option>
                ) : (
                  <option value={""}>Please Select User Role first</option>
                )}
                {reportingTo &&
                  reportingTo?.map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <span>password</span>
              <input
                placeholder="*******"
                value={formData.password}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    password: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <Button
                btnname={"Save"}
                onclick={handleSubmit}
                width={"112px"}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSubmit();
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast.submit}
      />
    </>
  );
};
export default UserPopup;
