// Importing necessary dependencies and styles
import React from "react";
import "./UserCards.scss"; // Custom styles for the component
import activeuser from "./../../../Assets/UserIcon/active 1.svg"; // Active user icon
import inactiveuser from "./../../../Assets/UserIcon/lazy 1.svg"; // Inactive user icon
import totaluser from "./../../../Assets/UserIcon/totalusers.svg"; // Total user icon

// Functional component for user cards displaying user statistics
const UserCards = ({ userList }) => {
  // Data for user cards, including title, icon, count, and bar color
  const userCardData = [
    {
      title: "Total Users",
      img: totaluser, // Total user icon
      count: userList.length, // Total user count (length of user list)
      barcolor: "#4231FF", // Bar color for styling
    },
    {
      title: "Active Users",
      img: activeuser, // Active user icon
      count: userList.length, // Placeholder count for active users
      barcolor: "#159300", // Bar color for styling
    },
    {
      title: "Inactive Users",
      img: inactiveuser, // Inactive user icon
      count: "0", // Placeholder count for inactive users
      barcolor: "#D80621", // Bar color for styling
    },
  ];

  // Rendering user cards based on the provided data
  return (
    <div className="df user-cards">
      {/* Mapping through userCardData array to generate user cards */}
      {userCardData.map((item, index) => (
        <div
          className="df user-box"
          key={index}
          style={{ backgroundColor: item.barcolor }} // Applying bar color as background
        >
          <div className="df user-container">
            {/* Displaying user title and count */}
            <div className="df user-left">
              <span className="user-title">{item.title}</span>
              <span className="user-count">{item.count}</span>
            </div>
            {/* Displaying user icon */}
            <img src={item.img} alt="icon" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserCards;
