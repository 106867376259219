import React from "react";
import Pagenotfound from "../Assets/pagenotfound.svg";
const PageNotFound = () => {
  return (
    <section style={{ height: "100vh" }}>
      <div className="df notaccess">
        <img src={Pagenotfound} alt="Page not found" />
      </div>
    </section>
  );
};

export default PageNotFound;
