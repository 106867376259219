import React, { useState, useEffect } from "react";
import "./SalesTracker.scss";
import EditPopup from "../STeditPopup/STeditPopup";
import EditPopup1 from "../STeditPopup/STeditPopup1";
import down from "../../../../Assets/EnquiresIcon/down.svg";
import filter from "../../../../Assets/EnquiresIcon/filter.svg";
import filteron from "../../../../Assets/EnquiresIcon/filteron.svg";

import _http from "../../../../Utils/Api/_http";
import FilterEnquires from "../Filter/FilterEnquires";
import { Popover, Space } from "antd";
import SnackBar from "../../../../Ui/SnackBar";
import { Tooltip } from "react-tooltip";

const SalesTracker = ({
  title,
  UserDataTable,
  api,
  AwaitingOrder,
  OrderPlaced,
}) => {
  // State variables
  const [open, setOpen] = React.useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [loading, setLoading] = useState(false);
  const [openfilter, setOpenfilter] = useState(false);
  const [filteredData, setFilteredData] = useState({
    reference_number: "",
    sales_person_name: "",
    client_person_name: "",
    client_subject: "",
    reminder_status1: "",
    reminder_status2: "",
  });
  const [filterOn, setFilterOn] = useState(false);
  const [toast, setToast] = useState({ delete: false, error: false });
  const [errors, setError] = useState("");
  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
  };
  // Function to handle closing snackbar
  const handletoast = () => {
    setToast({ error: false, delete: false });
  };

  // Function to handle delete operation
  const handleDelete = async (reference_number, sales_mail, index) => {
    try {
      await _http.post(
        `/api/delete`,
        { rfrnc_num: reference_number, sales_mail: sales_mail },
        { headers }
      );
      handleClose();
      setError("Successfully deleted");
      setToast({ delete: true });
      fetchData();
    } catch (error) {
      console.log(error);
      setToast({ error: true });
      setError(error.message);
    }
  };

  // Function to handle update operation
  const handleUpdate = async (ref_num, email, updatedData) => {
    const updatedDatas = JSON.stringify(updatedData);

    try {
      await _http.put(
        `/api/update/${ref_num}`,
        { email: email, jsondata: updatedDatas },
        { headers }
      );
      setError("Successfully updated");
      setToast({ delete: true });
      fetchData();
    } catch (error) {
      setToast({ error: true });
      setError(error.message);
    }

    handleClose();
  };

  const handleOrder = async (reference_number, updatedData, email) => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("jsondata", JSON.stringify(updatedData));
    try {
      await _http.put(
        `https://spira.admin.dtskill.com/api/update/${reference_number}`,
        formData,
        { headers }
      );
      setError("Successfully updated");
      setToast({ delete: true });
      handleClose();
      fetchData();
    } catch (error) {
      setToast({ error: true });
      setError(error.message);
    }
  };

  // Effect to fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await _http.get(api, { headers });

      if (Array.isArray(response.data) && response.data.every(Array.isArray)) {
        const flattenedData = response.data.flat();
        setUsers(flattenedData.flat());
      } else {
        setUsers(response.data);
        // setToast({ error: true });
        // setError("Unexpected API response format:", response.data);
      }
    } catch (error) {
      setToast({ error: true });
      setError("Error fetching data:", error);
      console.log(error);
    }
    setLoading(false);
  };
  // Function to handle opening user details
  const handleOpen = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  // Function to handle closing user details
  const handleClose = () => setOpen(false);

  // Function to hide filter popover
  const hide = () => {
    setOpenfilter(false);
  };

  // Function to handle filter change
  const handleOpenChange = (newOpen) => {
    setOpenfilter(newOpen);
  };

  // Function to handle filter
  const handleFilter = (data) => {
    setFilteredData(data);
    const isFilterOn =
      data.reference_number !== "" ||
      data.sales_person_name !== "" ||
      data.client_person_name !== "" ||
      data.client_subject !== "" ||
      data.reminder_status1 !== "" ||
      data.reminder_status2 !== "";
    setFilterOn(isFilterOn);
  };

  // to render the datas in the table
  const renderEmptyOrValue = (text, TT, cln) => {
    return text ? (
      <span className={cln} style={{ textTransform: TT && "capitalize" }}>
        {text}
      </span>
    ) : (
      <span className={"empty"}>Empty</span>
    );
  };
  // JSX rendering
  return (
    <section>
      <div className="container1">
        <div className="container-head">
          <p className="title">{title}</p>
          <Popover
            trigger="click"
            open={openfilter}
            placement="bottomRight"
            onOpenChange={handleOpenChange}
            content={<FilterEnquires onFilter={handleFilter} hide={hide} />}
          >
            <Space>
              <span
                className="filter-head"
                data-tooltip-id="tooltip-arrow"
                data-tooltip-content="Filter"
              >
                <img src={filterOn ? filteron : filter} alt="icon" />{" "}
                <img src={down} alt="icon" />
              </span>
            </Space>
          </Popover>
        </div>

        <UserDataTable
          handleOpen={handleOpen}
          users={users}
          filteredData={filteredData}
          loading={loading}
          renderEmptyOrValue={renderEmptyOrValue}
        />
        {open &&
          selectedUser &&
          (AwaitingOrder || OrderPlaced ? (
            <EditPopup1
              open={open}
              user={selectedUser}
              handleClose={handleClose}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              handleOrder={handleOrder}
            />
          ) : (
            <EditPopup
              open={open}
              user={selectedUser}
              handleClose={handleClose}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
            />
          ))}
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast.delete}
      />
      <Tooltip
        id="tooltip-arrow"
        place="bottom"
        style={{ fontSize: "16px", padding: "7px" }}
      />
    </section>
  );
};

export default SalesTracker;
