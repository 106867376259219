// Login.jsx
import React, { useState } from "react";
import _nonAuthHttp from "../../../Utils/Api/_nonAuthHttp";
import { useNavigate } from "react-router-dom";
import LoginImage from "./../../../Assets/LoginPage/Login-image.svg";
import "./Login.scss";
import spiraLogo from "./../../../Assets/LoginPage/spira 1.svg";
import { Link } from "react-router-dom";
import Button from "../../../Ui/Button/Button";
import SnackBar from "../../../Ui/SnackBar";
import { useDispatch } from "react-redux";
import { setUserdata } from "../../../Redux/Reducers";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// Main Login Component
export default function Login() {
  // State variables for managing input fields, loading state, errors, and SnackBar visibility
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [currentUser, setCurrentUser] = useState(false);
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [errors, setErrors] = useState("");
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // Function to close the SnackBar
  const handleClose = () => {
    setOpen(false);
  };

  // Event handler for username input change
  const usernameChange = (event) => {
    setUsername(event.target.value);
  };

  // Event handler for password input change
  const passwordChange = (event) => {
    setPassword(event.target.value);
  };

  // Function to validate login input
  const validateLoginInput = (username, password) => {
    if (username.length === 0 || password.length === 0) {
      return "Please fill out the fields";
    }
    return null;
  };

  // Function to handle the login process
  const handleLogin = async (e) => {
    setloading(true);
    e.preventDefault();

    // Validate login input
    const inputError = validateLoginInput(username, password);

    if (inputError) {
      setOpen(true);
      setErrors(inputError);
      setloading(false);
      return;
    }

    try {
      // Make a login request using _nonAuthHttp
      const res = await _nonAuthHttp.post("/Login", {
        email: username,
        password: password,
      });

      if (res.data.error) {
        // Handle login error
        setErrors(res.data.error);
        setCurrentUser(false);
      } else {
        // Store user data in sessionStorage on successful login
        sessionStorage.setItem("jwtToken", res.data.output.access_token);
        sessionStorage.setItem("Name", res.data.output.username);
        sessionStorage.setItem(
          "access",
          JSON.stringify(res.data.output.accessibilitys)
        );
        sessionStorage.setItem("Role", res.data.output.Role);
        sessionStorage.setItem("UserName", res.data.output.Name);
        setCurrentUser(true);
        dispatch(setUserdata(res.data.output));
        // Navigate to the home page
        navigate("/SpiraAI/Dashboard");
      }
    } catch (error) {
      // Handle login error exceptions
      setOpen(true);

      if (error.response && error.response.status === 500) {
        setErrors("Internal server error ");
      } else if (error.response && error.response.status === 400) {
        setErrors("Login failed: Incorrect Username or Password provided.");
      } else if (error.response && error.response.status === 401) {
        setErrors("Login failed: Incorrect Username or Password provided");
      } else {
        setErrors(error.message);

        console.error("Error during login:", error);
      }
    } finally {
      setCurrentUser(false);
      setloading(false);
    }
  };

  // Login component JSX
  return (
    <div className="newLogin flexBox">
      <div className="left flexBox">
        <img src={LoginImage} alt="CompanyLogo"></img>
      </div>
      <div className={`right flexBox`}>
        <div>
          <h1>Empowering Connections: Login to Spira Power AI</h1>
          <div className={`login-card `}>
            <div className="login-logo login-title">
              <img src={spiraLogo} alt="img" />
            </div>

            {currentUser ? (
              <div className="text-center mb-5">Login successful!</div>
            ) : (
              <form className="login-card-form">
                <label htmlFor="username">Spira Power Email ID</label>

                <input
                  type="text"
                  name="username"
                  id="username"
                  placeholder="Johndoe@spirapower.com"
                  value={username}
                  onChange={usernameChange}
                  className="txtBox"
                  required
                />

                <label htmlFor="password">Password</label>
                <div className="password-input txtBox">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="*********"
                    onChange={passwordChange}
                    className=""
                    autoComplete="on"
                    required
                  />
                  <span
                    className="password-toggle-icon"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                {
                  // SnackBar component for displaying errors
                  <SnackBar
                    message={errors}
                    severity={"error"}
                    handleClose={handleClose}
                    Open={open}
                  />
                }

                <div className="forgot-pass" to="/EmailVerify">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Remember me
                    </label>
                  </div>
                  <Link className="forget-pass" to="#">
                    Forgot Password?
                  </Link>
                </div>

                <div className="login-btn">
                  {/* Login button component with loading state handling */}
                  <Button
                    onclick={handleLogin}
                    btnname={loading ? "LOGING IN..." : "LOGIN"}
                    width={"100%"}
                    height={50}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleLogin();
                      }
                    }}
                  />
                  <div className="login-title dont-have-acc ">
                    Haven't registered Yet?
                    <Link className="subtitle" to="/Register">
                      Register with Spira Power AI
                    </Link>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
