import React, { useMemo } from "react";
import { Table } from "antd";
import "./../../SalesTracker/table.scss";

const OPtable = ({
  handleOpen,
  users,
  filteredData,
  loading,
  renderEmptyOrValue,
}) => {
  console.log(filteredData);

  const baseFontSize = 16;

  const rem = (pixels, context = baseFontSize) => {
    if (typeof pixels === "number") {
      pixels = pixels + "px";
    }

    if (typeof context === "number") {
      context = context + "px";
    }

    // Extract numeric values from pixel strings
    const pixelValue = parseFloat(pixels);
    const contextValue = parseFloat(context);

    return `${pixelValue / contextValue}rem`;
  };

  // useMemo hook to filter and format user data based on filter criteria
  const filteredUsers = useMemo(() => {
    return (users || []).filter((user) => {
      return (
        (user?.reference_number || "")
          .toLowerCase()
          .includes((filteredData?.reference_number || "").toLowerCase()) &&
        (user?.sales_person_name || "")
          .toLowerCase()
          .includes((filteredData?.sales_person_name || "").toLowerCase()) &&
        (user?.client_person_name || "")
          .toLowerCase()
          .includes((filteredData?.client_person_name || "").toLowerCase()) &&
        (user?.client_subject || "")
          .toLowerCase()
          .includes((filteredData?.client_subject || "").toLowerCase()) &&
        (filteredData.reminder_status1
          ? new Date(user.reminder_status) <=
            new Date(filteredData.reminder_status1)
          : true) &&
        (filteredData.last_login1
          ? new Date(user.reminder_status) >=
            new Date(filteredData.reminder_status2)
          : true)
      );
    });
    // .map((user) => {
    //   const orderClosureDays = Math.floor(
    //     (new Date(user?.reminder_status) - new Date()) / (1000 * 60 * 60 * 24)
    //   );
    //   const formattedOrderClosureDays =
    //     orderClosureDays >= 7
    //       ? `${Math.floor(orderClosureDays / 7)} weeks`
    //       : `${orderClosureDays} days`;

    //   return {
    //     ...user,
    //     orderClosureDays: formattedOrderClosureDays,
    //   };
    // });
  }, [users, filteredData]);

  // Columns configuration for Ant Design Table
  const columns = [
    {
      title: "Reference Number",
      dataIndex: "reference_number",
      key: "reference_number",
      width: rem(180),
      className: "tl",
      sorter: (a, b) => a.reference_number.localeCompare(b.reference_number),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Sales Person Name",
      dataIndex: "sales_person_name",
      key: "sales_person_name",
      className: "tl",
      sorter: (a, b) => a.sales_person_name.localeCompare(b.sales_person_name),
      render: (text) => renderEmptyOrValue(text, true),
      width: rem(180),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // sorter: (a, b) => a.status || "".localeCompare(b.status || ""),
      width: rem(160),
      render: (text, record) => {
        return <span className="op status">Order Placed</span>;
        // if (record.ack_time === "nan" && record.quotation_time === "Null") {
        //   return "empty";
        // } else if (
        //   record.ack_time !== "nan" &&
        //   record.quotation_time === "Null"
        // ) {
        //   return <span className="aqo status">Awaiting quote</span>;
        // } else if (record.quotation_time !== "Null") {
        //   return <span className="awo status">Awaiting order</span>;
        // } else {
        //   return <span className="op status">Order Placed</span>;
        // }
      },
    },
    {
      title: "Order Value",
      dataIndex: "order_value",
      key: "order_value",
      sorter: (a, b) => a.order_value.localeCompare(b.order_value),
      width: rem(140),
      render: (text) => renderEmptyOrValue(text),
    },

    {
      title: "Client Email",
      dataIndex: "client_email",
      key: "client_email",
      width: rem(200),
      className: "tl",

      render: (text) => renderEmptyOrValue(text),
      sorter: (a, b) => a.client_email.localeCompare(b.client_email),
    },
    {
      title: "Order Date Time",
      dataIndex: "order_date_time",
      key: "order_date_time",
      sorter: (a, b) => a.order_date_time.localeCompare(b.order_date_time),
      render: (text) => renderEmptyOrValue(text),
      width: rem(240),
    },
    {
      title: "Order Closure Days",
      dataIndex: "client_email_time",
      key: "client_email_time",
      render: (text) => renderEmptyOrValue(text),
      width: rem(180),
    },
  ];
  console.log(filteredUsers);
  return (
    <div className="table-wrap">
      <Table
        className="user-table"
        columns={columns}
        dataSource={
          filteredUsers &&
          filteredUsers.map((item, index) => ({
            ...item,
            key: index,
          }))
        }
        scroll={{
          y: `calc(100vh - ${rem(290)}`,
        }}
        pagination={{
          // pageSize: 1,
          showQuickJumper: true,
          showSizeChanger: true,
          itemRender: (current, type, originalElement) => {
            if (type === "prev" || type === "next") {
              return (
                <span className="pagination-button">{originalElement}</span>
              );
            }
            return originalElement;
          },
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          className: "paginate",
        }}
        onRow={(record) => ({
          onClick: () => handleOpen(record),
        })}
        loading={loading}
      />{" "}
    </div>
  );
};

export default OPtable;
