import React, { useState, useEffect } from "react";
import "./UserPopup/UserPopup.scss";
import "../SalesTracker/STcomponents/STeditPopup/STeditPopup.scss";
import { Modal } from "antd";
import Button from "../../Ui/Button/Button";

const UserActions = ({
  open,
  handleClose,
  users,
  handleDelete,
  handleUpdate,
  roles,
  userList,
  names,
}) => {
  const [formData, setFormData] = useState(users);
  const permissions = [
    "sales_tracker",
    "user_management",
    "quality",
    "procurement",
    "quote_generator",
  ];

  const baseFontSize = 16;

  const rem = (pixels, context = baseFontSize) => {
    if (typeof pixels === "number") {
      pixels = pixels + "px";
    }

    if (typeof context === "number") {
      context = context + "px";
    }

    // Extract numeric values from pixel strings
    const pixelValue = parseFloat(pixels);
    const contextValue = parseFloat(context);

    return `${pixelValue / contextValue}rem`;
  };
  const [reportingTo, setReportingTo] = useState([]);

  const roleTypes = () => {
    if (roles === "admin") return ["Manager", "Teamlead", "employee"];
    if (roles === "Manager") return ["Teamlead", "employee"];
    if (roles === "Teamlead") return ["employee"];
    return [];
  };

  const getReportingToOptions = (roleName) => {
    let reportingToOptions = [];

    if (roleName === "Manager") {
      reportingToOptions = [names];
    } else if (roleName === "Teamlead") {
      const managers = userList
        .filter((user) => user.role_name === "Manager")
        .map((user) => user.user);
      reportingToOptions = managers.length > 0 ? managers : [names];
    } else if (roleName === "employee") {
      const teamleads = userList
        .filter((user) => user.role_name === "Teamlead")
        .map((user) => user.user);
      reportingToOptions = teamleads.length > 0 ? teamleads : [names];
    }

    if (roles === formData.role_name) {
      reportingToOptions = reportingToOptions.filter(
        (option) => option !== formData.user
      );
    }

    setReportingTo(reportingToOptions);
  };

  useEffect(() => {
    getReportingToOptions(formData.role_name);
  }, [roles, formData.role_name]);

  const handleDeleteClick = () => {
    handleDelete(formData.user, formData.email);
  };

  const handleUpdateClick = () => {
    if (
      !formData.user ||
      !formData.email ||
      !formData.role_name ||
      !formData.reporting_to
    ) {
      console.log("All fields are required");
      handleUpdate(false);
      return;
    }

    const updatedData = { email: formData.email };
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== users[key]) {
        updatedData[key] = formData[key];
      }
    });

    if (Object.keys(updatedData).length === 1) {
      console.log("No changes made");
      return;
    }

    handleUpdate({ email: formData.email, data: updatedData });
  };

  const handlePermissionChange = (type) => {
    setFormData((prevData) => ({ ...prevData, [type]: !prevData[type] }));
  };

  return (
    <Modal
      title=" "
      centered
      open={open}
      onCancel={handleClose}
      width={rem(984)}
      className="edit-popup"
    >
      <div className="user-popup">
        <div className="user-popup-left df">
          <div>
            <span>Full Name</span>
            <input
              placeholder="Full Name"
              value={formData.user}
              onChange={(e) =>
                setFormData({ ...formData, user: e.target.value })
              }
            />
          </div>
          <div>
            <span>Email</span>
            <input
              placeholder="Email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </div>
          <div>
            <span>User Role</span>
            <select
              className="form-select"
              value={formData.role_name}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  role_name: e.target.value,
                  reporting_to: "",
                })
              }
            >
              <option value="">Select User Role</option>
              {roleTypes().map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="user-popup-right df">
          <div>
            <span>Reporting To</span>
            <select
              className="form-select"
              value={formData.reporting_to}
              onChange={(e) =>
                setFormData({ ...formData, reporting_to: e.target.value })
              }
            >
              {formData.role_name ? (
                <option value={""}>Select Reporting to</option>
              ) : (
                <option value={""}>Please Select User Role first</option>
              )}
              {reportingTo &&
                reportingTo?.map(
                  (option, i) =>
                    // Only render formData.user if formData.role_name is not equal to roles
                    formData.user !== option && (
                      <option key={i} value={option}>
                        {option}
                      </option>
                    )
                )}
            </select>
          </div>
          <div>
            <span>Permissions</span>
            <div className="permission-box">
              {permissions.map((data, i) => (
                <div key={i} className="permission">
                  <input
                    type="checkbox"
                    checked={formData[data]}
                    onChange={() => handlePermissionChange(data)}
                  />
                  <label>{data}</label>
                </div>
              ))}
            </div>
          </div>
          <div>
            <span className="df api-btn">
              <Button btnname="Delete" onclick={handleDeleteClick} />
              <Button onclick={handleUpdateClick} btnname="Update" />
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UserActions;
