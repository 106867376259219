import React, { useEffect, useState, useMemo } from "react";
import { Table, Popover, Space } from "antd";
import "../../SalesTracker/table.scss";
import _http from "../../../Utils/Api/_http";
import UserCards from "../UserCards/UserCards";
import searchIcon from "../../../Assets/UserIcon/usersearchicon.svg";
import down from "../../../Assets/EnquiresIcon/down.svg";
import filter from "../../../Assets/EnquiresIcon/filter.svg";
import filteron from "../../../Assets/EnquiresIcon/filteron.svg";
import "./user.scss";
import UserPopup from "../UserPopup/UserPopup";
import Button from "../../../Ui/Button/Button";
import FilterUser from "../FilterUser/FilterUser";
import UserActions from "../UserActions";
import SnackBar from "../../../Ui/SnackBar";
import { Tooltip } from "react-tooltip";

const User = () => {
  // State variables

  const [userList, setUserList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [roles, setRole] = useState("");
  const [names, setName] = useState("");
  const [tableData, setTableData] = useState(userList);
  const [openfilter, setOpenfilter] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [filteredData, setFilteredData] = useState({
    user: "",
    role_name: "",
    reporting_to: "",
    created_date1: "",
    created_date2: "",
    last_login1: "",
    last_login2: "",
  });
  const [filterOn, setFilterOn] = useState(false);
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
  };
  // Function to handle toast closing
  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const baseFontSize = 16;

  const rem = (pixels, context = baseFontSize) => {
    if (typeof pixels === "number") {
      pixels = pixels + "px";
    }

    if (typeof context === "number") {
      context = context + "px";
    }

    // Extract numeric values from pixel strings
    const pixelValue = parseFloat(pixels);
    const contextValue = parseFloat(context);

    return `${pixelValue / contextValue}rem`;
  };

  // Function to handle user deletion
  const handleDelete = async (user, email, index) => {
    try {
      await _http.post(`delete`, { username: user, email: email }, { headers });
      const updatedData = [...userList];
      updatedData.splice(index, 1);
      setUserList(updatedData);
      handleClose();
      fetchData();
      setError("Successfully deleted");
      setToast({ submit: true });
    } catch (error) {
      console.log(error);
      setToast({ error: true });
      setError(error.message);
    }
  };
  // Function to handle user update
  const handleUpdate = async (updatedData) => {
    if (!updatedData) {
      setToast({ error: true });
      setError("Please fill all the fields");
      return;
    }
    try {
      await _http.post(`/update-user`, updatedData, {
        headers,
      });
      fetchData();
      setToast({ submit: true });
      setError("Successfully updated");
    } catch (error) {
      setToast({ error: true });
      setError(error.message);
    }
    handleClose();
  };
  // Effect to update table data when userList changes
  useEffect(() => {
    setTableData(userList);
  }, [userList, tableData]);
  // Memoized filter for user list
  const filteredUsers = useMemo(() => {
    return (userList || []).filter(
      (user) =>
        (user.user || "")
          .toLowerCase()
          .includes((filteredData.user || "").toLowerCase()) &&
        (user.role_name || "")
          .toLowerCase()
          .includes((filteredData.role_name || "").toLowerCase()) &&
        (user.reporting_to || "")
          .toLowerCase()
          .includes((filteredData.reporting_to || "").toLowerCase()) &&
        // Check if created_date is within the specified range
        (filteredData.created_date1
          ? new Date(user.created_date) >= new Date(filteredData.created_date1)
          : true) &&
        (filteredData.created_date2
          ? new Date(user.created_date) <= new Date(filteredData.created_date2)
          : true) &&
        (filteredData.last_login1
          ? new Date(user.last_login) >= new Date(filteredData.last_login1)
          : true) &&
        (filteredData.last_login2
          ? new Date(user.last_login) <= new Date(filteredData.last_login2)
          : true)
    );
  }, [userList, filteredData]);
  // Function to fetch user data
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await _http.get("/api/get_users", { headers });
      const userLists = response.data.Output.record.flat();
      setUserList(userLists.flat());

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setToast({ error: true });
      setError("Error fetching data:", error.message);
    }
  };
  // Effect to fetch user data on component mount
  useEffect(() => {
    fetchData();
    setName(sessionStorage.getItem("UserName"));
    setRole(sessionStorage.getItem("Role"));
  }, []);

  // to render the datas in the table
  const renderEmptyOrValue = (text, TT, cln) => {
    return text ? (
      <span className={cln} style={{ textTransform: TT && "capitalize" }}>
        {text}
      </span>
    ) : (
      <span className={"empty"}>Empty</span>
    );
  };
  // Table columns configuration
  const columns = [
    {
      title: "Name",
      dataIndex: "user",
      key: "user",
      className: "tl",

      render: (text, record) => (
        <>
          {renderEmptyOrValue(text, false, "client-name")}
          <br />
          {renderEmptyOrValue(record.email, false, "email")}
        </>
      ),
      sorter: (a, b) => a.user.localeCompare(b.user),
      width: rem(180),
    },
    {
      title: "Role",
      dataIndex: "role_name",
      key: "role_name",
      render: (text) => (
        <span
          style={{ textTransform: "capitalize" }}
          className={`role ${
            (text.toLowerCase() === "manager" && "manager") ||
            (text.toLowerCase() === "teamlead" && "teamlead") ||
            (text.toLowerCase() === "employee" && "employee") ||
            (text.toLowerCase() === "admin" && "admin")
          }`}
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.role_name.localeCompare(b.role_name),

      width: rem(120),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (text) => (
        <span className="user-active">
          {" "}
          <div className={"greenbox" || "redbox"}></div>
          {"Active"}
        </span>
      ),
      width: rem(120),
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      className: "tl",
      sorter: (a, b) => a.last_login?.localeCompare(b.last_login),

      render: (text) => {
        if (text) {
          const date = new Date(text);
          const formattedDateTime = `${date.getDate()}-${(date.getMonth() + 1)
            .toString()
            .padStart(
              2,
              "0"
            )}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
          return <p>{formattedDateTime}</p>;
        }
        return <span className="empty">Empty</span>;
      },
      width: rem(150), // Adjust the width to accommodate the time
    },
    {
      title: "Joined Date",
      dataIndex: "created_date",
      key: "created_date",
      className: "tl",
      sorter: (a, b) => a.created_date?.localeCompare(b.created_date),

      render: (text) => {
        if (text) {
          const date = new Date(text);
          const formattedDateTime = `${date.getDate()}-${(date.getMonth() + 1)
            .toString()
            .padStart(
              2,
              "0"
            )}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
          return <p>{formattedDateTime}</p>;
        }
        return <span className="empty">Empty</span>;
      },
      width: rem(150), // Adjust the width to accommodate the time
    },
  ];

  // Function to handle modal close
  const handleClose = () => setOpen(false);

  // Function to handle new user modal close
  const handleCloseNewuser = () => setNewUser(false);

  // Function to hide filter popover
  const hide = () => {
    setOpenfilter(false);
  };

  // Function to handle filter popover visibility change
  const handleOpenChange = (newOpen) => {
    setOpenfilter(newOpen);
  };

  // Function to handle filter criteria change
  const handleFilter = (data) => {
    setFilteredData(data);
    const isFilterOn =
      data.user !== "" ||
      data.role_name !== "" ||
      data.reporting_to !== "" ||
      data.created_date1 !== "" ||
      data.created_date2 !== "" ||
      data.last_login1 !== "" ||
      data.last_login2 !== "";

    setFilterOn(isFilterOn);
  };

  return (
    <section>
      <div className="container1">
        <div className="container-head">
          <p className="title">Users</p>
          <div className="df add-user">
            <div className="user-search df">
              <img src={searchIcon} alt="icon" />
              <input
                type="text"
                placeholder="Search User"
                value={filteredData.user}
                onChange={(e) => {
                  setFilteredData({ ...filteredData, user: e.target.value });
                }}
              />
            </div>
            <Popover
              trigger="click"
              open={openfilter}
              placement="bottomRight"
              onOpenChange={handleOpenChange}
              content={
                <FilterUser
                  onFilter={handleFilter}
                  hide={hide}
                  roles={roles}
                  names={names}
                  userList={userList}
                />
              }
            >
              <Space>
                <span
                  className="filter-head"
                  data-tooltip-id="tooltip-arrow"
                  data-tooltip-content="Filter"
                >
                  <img src={filterOn ? filteron : filter} alt="icon" />{" "}
                  <img src={down} alt="icon" />
                </span>
              </Space>
            </Popover>
            <Button
              btnname={"+ Add New User"}
              onclick={() => {
                setNewUser(true);
              }}
              width={148}
              height={38}
            />
          </div>
        </div>
        <div className="users" style={{ paddingBottom: rem(32) }}>
          <UserCards userList={userList} />
        </div>
        <div className="table-wrap">
          {
            <Table
              className="user-table"
              columns={columns}
              filteredData={filteredData}
              dataSource={
                filteredUsers &&
                filteredUsers.map((item, index) => ({
                  ...item,
                  key: index,
                }))
              }
              scroll={{
                y: `calc(100vh - ${rem(290)}`,
              }}
              pagination={{
                // pageSize: 5,

                showQuickJumper: true,
                showSizeChanger: true,
                itemRender: (current, type, originalElement) => {
                  if (type === "prev" || type === "next") {
                    return (
                      <span className="pagination-button">
                        {originalElement}
                      </span>
                    );
                  }
                  return originalElement;
                },
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                className: "paginate",
              }}
              onRow={(record) => ({
                onClick: () => (setSelectedUser(record), setOpen(true)),
              })}
              loading={loading}
            />
          }
        </div>
        <UserPopup
          open={newUser}
          handleClose={handleCloseNewuser}
          fetchData={fetchData}
          roles={roles}
          names={names}
          userList={userList}
          headers={headers}
        />
        {open && selectedUser && (
          <UserActions
            open={open}
            handleClose={handleClose}
            fetchData={fetchData}
            users={selectedUser}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
            roles={roles}
            names={names}
            userList={userList}
          />
        )}
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast.submit}
      />
      <Tooltip
        id="tooltip-arrow"
        place="bottom"
        style={{ fontSize: "16px", padding: "7px" }}
      />
    </section>
  );
};

export default User;
