import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./SideBar.scss";
import Home from "./../../Assets/SideBarIcon/Home.svg";
import QuoteGenerator from "./../../Assets/SideBarIcon/Speech bubble.svg";
// import Procurement from "./../../Assets/SideBarIcon/Deal.svg";
import Quality from "./../../Assets/SideBarIcon/Medal.svg";
import Sales from "./../../Assets/SideBarIcon/Sales.svg";
import arrowIcon from "./../../Assets/SideBarIcon/arrowIcon.svg";
import Homeactive from "./../../Assets/SideBarIcon/Homeactive.svg";
import QuoteGeneratoractive from "./../../Assets/SideBarIcon/Speech bubbleactive.svg";
// import Procurementactive from "./../../Assets/SideBarIcon/Dealactive.svg";
import Qualityactive from "./../../Assets/SideBarIcon/MedalActive.svg";
import Salesactive from "./../../Assets/SideBarIcon/Salesactive.svg";
import arrowIconactive from "./../../Assets/SideBarIcon/Arrowactive.svg";
import Useractive from "./../../Assets/SideBarIcon/Useractive.svg";
// import heat from "./../../Assets/SideBarIcon/heat.svg";
// import heatActive from "./../../Assets/SideBarIcon/heatnumberActive.svg";
import feedback from "./../../Assets/SideBarIcon/feedback.svg";
import feedbackActive from "./../../Assets/SideBarIcon/feedactive.svg";
import User from "./../../Assets/SideBarIcon/User.svg";
import sideBarClose from "../../Assets/SidebarClose.svg";
import { selectIsToggleOn, toggle } from "../../Redux/Reducers";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";

// Custom hook to handle sessionStorage operations
const useSessionStorage = (key, initialValue) => {
  const storedValue = sessionStorage.getItem(key);
  const initial = storedValue ? new Map(JSON.parse(storedValue)) : initialValue;
  const [value, setValue] = useState(initial);
  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(Array.from(value.entries())));
  }, [key, value]);

  return [value, setValue];
};
const SideBar = () => {
  // State hooks
  const [dropdownState, setDropdownState] = useSessionStorage(
    "dropdownState",
    new Map()
  );
  const [access, setAccess] = useState();

  // Redux hooks
  const dispatch = useDispatch();
  const isSideBarOn = useSelector(selectIsToggleOn);

  // React Router hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Effect to set access state from sessionStorage
  useEffect(() => {
    const access = JSON.parse(sessionStorage.getItem("access"));
    setAccess(access);
  }, []);
  // Sidebar data
  const sidebarData = [
    {
      text: "Home",
      link: "/SpiraAI/Dashboard",
      imgSrc: Home,
      activeImgSrc: Homeactive,
      alt: "side-img",
      dropdown: false,
      linksactive: true,
      permission: true,
      arrowImage: false,
    },

    {
      text: "Sales Tracker",
      link: "#",
      activeImgSrc: Salesactive,
      imgSrc: Sales,
      alt: "side-img",
      dropdown: true,
      permission: access?.sales_tracker,
      arrowImage: true,
      dropDownData: [
        { title: "Dashboard", link: "/Salestracker/Dashboard" },
        { title: "All Enquiries", link: "/enquires" },
        { title: "Awaiting Quotes", link: "/awaiting-quotes" },
        { title: "Awaiting Order", link: "/awaiting-order" },
        { title: "Order Placed", link: "/order-placed" },
        { title: "Report", link: "/Salestracker/report" },
      ],
    },
    {
      text: "User Management",
      link: "#",
      activeImgSrc: Useractive,
      imgSrc: User,
      alt: "side-img",
      dropdown: true,
      permission: access?.user_management,
      arrowImage: true,
      dropDownData: [{ title: "Users", link: "/user" }],
    },
    {
      text: "Quality",
      link: "/QualityTeam.MTCgenerator",
      activeImgSrc: Qualityactive,
      imgSrc: Quality,
      alt: "side-img",
      permission: access?.quality,
      linksactive: true,
      arrowImage: false,
    },
    // {
    //   text: "Procurement",
    //   link: "/Procurement",
    //   activeImgSrc: Procurementactive,
    //   imgSrc: Procurement,
    //   alt: "side-img",
    //   permission: access?.procurement,
    //   dropdown: false,
    //   arrowImage: false,
    // },
    {
      text: "Quote Generator",
      link: "/QuoteGenerator",
      activeImgSrc: QuoteGeneratoractive,
      imgSrc: QuoteGenerator,
      alt: "side-img",
      permission: access?.quote_generator,
      dropdown: false,
      arrowImage: false,
    },
    {
      text: "Feedback",
      link: "#",
      imgSrc: feedback,
      activeImgSrc: feedbackActive,
      alt: "side-img",
      dropdown: true,
      linksactive: true,
      permission: true,
      arrowImage: true,
      dropDownData: [
        { title: "Feedback Form", link: "/feedbackform" },
        { title: "Feedback Data", link: "/feedbackdata" },
      ],
    },
  ];

  // Function to handle dropdown toggle
  const handleDropDown = (index) => {
    const newDropdownState = new Map(dropdownState);
    // to toggle dropdown when click on other dropdown
    // for (const [i, isOpen] of newDropdownState.entries()) {
    //   if (i !== index && isOpen) {
    //     newDropdownState.set(i, false);
    //   }
    // }

    newDropdownState.set(index, !newDropdownState.get(index));
    // while closing sidebar set dropdowns to hide
    if (!isSideBarOn) {
      for (const [i] of newDropdownState.entries()) {
        newDropdownState.set(i, false);
      }
    }

    setDropdownState(newDropdownState);

    const item = sidebarData[index];
    if (item.link && !item.dropdown) {
      navigate(item.link);
    }

    sessionStorage.setItem(
      "dropdownState",
      JSON.stringify(Array.from(newDropdownState.entries()))
    );
    // to handle dropdown when sidebar is minimised
    if (item.dropdown && !isSideBarOn) {
      dispatch(toggle());
      for (const [i] of newDropdownState.entries()) {
        if (i === index) {
          newDropdownState.set(i, true);
        }
      }
    }
  };

  // Function to handle sidebar toggle
  const handleSideBar = () => {
    const newDropdownState = new Map(dropdownState);
    // Toggle the sidebar state
    dispatch(toggle());
    setDropdownState(new Map(newDropdownState)); // Ensure to create a new Map instance
  };

  // Function to handle link click
  const handleLinkClick = (event, link) => {
    navigate(link);
    event.stopPropagation();
  };
  // Function to check if dropdown is active
  const isActiveDropdown = (index) =>
    dropdownState.get(index) && sidebarData[index].dropdown;

  // Filter permitted items based on user access
  const permittedItems = sidebarData?.filter((item) => item.permission);

  // Map sidebar elements
  const sidebarElements = permittedItems?.map((item, index) => (
    <li
      onClick={() => handleDropDown(index)}
      key={index}
      className={`${
        isSideBarOn && isActiveDropdown(index) ? "d-height" : "d-height1"
      } `}
    >
      <div
        className={`sidebar-link df Color${
          location.pathname === item.link ||
          (item.dropDownData &&
            item.dropDownData.some(
              (dropItem) => location.pathname === dropItem.link
            ))
            ? "1"
            : ""
        }`}
        data-tooltip-id="tooltip-arrow"
        data-tooltip-content={isSideBarOn ? "" : item.text}
      >
        <div className="df sidebar-link-right">
          <img
            src={
              location.pathname === item.link ||
              (item.dropDownData &&
                item.dropDownData.some(
                  (dropItem) => location.pathname === dropItem.link
                ))
                ? item.activeImgSrc
                : item.imgSrc
            }
            alt={item.alt}
            className={`${
              dropdownState[index] && item.dropdown ? "Border-Color" : ""
            }`}
          />
          <p
            className={`${
              location.pathname === item.link ||
              (item.dropDownData &&
                item.dropDownData.some(
                  (dropItem) => location.pathname === dropItem.link
                ))
                ? "Border-Color"
                : "sidebar-link-title"
            }`}
          >
            {item.text}
          </p>
        </div>
        {item.arrowImage && (
          <div
            className={`homesidebar-rightarrow ${
              isActiveDropdown(index) ? "arrow-down" : ""
            }`}
          >
            <img
              src={
                location.pathname === item.link ? arrowIconactive : arrowIcon
              }
              alt="icon"
            />
          </div>
        )}
      </div>
      {/* // Mapping dropdown elements */}
      {isActiveDropdown(index) && (
        <div className="dropdown-line">
          <div className={` dropdown-link df`}>
            <ul className="df">
              {(item.dropDownData || []).map((dropItem, dropIndex) => (
                <div
                  className={`sidebar-dropdown ${
                    location.pathname === dropItem.link ? "activelink" : ""
                  }`}
                  key={dropIndex}
                >
                  <li
                    onClick={(event) => handleLinkClick(event, dropItem.link)}
                    className={`df ${
                      location.pathname === dropItem.link
                        ? "link-col"
                        : "link-col1"
                    } dropdown-links`}
                  >
                    {dropItem.title}
                  </li>
                </div>
              ))}
            </ul>
          </div>
        </div>
      )}
    </li>
  ));
  // Rendering the Sidebar component
  return (
    <aside style={{ position: "relative" }}>
      <ul className="home-sidebar">{sidebarElements}</ul>
      <div className={``} onClick={handleSideBar}>
        <img
          className={`toggle-sidebar-icon ${
            isSideBarOn ? "close-sidebar" : "open-sidebar"
          }`}
          src={sideBarClose}
          alt="icon"
          data-tooltip-id="tooltip-arrow"
          data-tooltip-content={isSideBarOn ? "Close Sidebar" : "Open Sidebar"}
        />
      </div>
      <Tooltip
        id="tooltip-arrow"
        place="bottom"
        style={{ fontSize: "16px", padding: "7px" }}
      />
    </aside>
  );
};

export default SideBar;
