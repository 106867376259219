import React, { useState, useEffect } from "react";
import Button from "../../../Ui/Button/Button";

const FilterUser = ({ onFilter, hide, roles, userList, names }) => {
  // State variables to store filter criteria
  const [filteredData, setFilteredData] = useState({
    user: "",
    role_name: "",
    reporting_to: "",
    created_date1: "",
    created_date2: "",
    last_login1: "",
    last_login2: "",
  });

  // State variable to store reportingTo options based on user roles
  const [reportingTo, setReportingTo] = useState();

  // State variable to store available roles based on user roles
  const [Roles, setRoles] = useState([]);

  // Function to determine role options based on the user's role
  const RoleType = () => {
    if (roles === "admin") {
      setRoles(["Manager", "Teamlead", "employee"]);
    } else if (roles === "Manager") {
      setRoles(["Teamlead", "employee"]);
    } else if (roles === "Teamlead") {
      setRoles(["employee"]);
    } else {
      return;
    }
    setReportingTo(); // Reset reportingTo on RoleType change
  };

  // Function to get reportingTo options based on selected role
  const getReportingToOptions = (role_names, names) => {
    let reportingToOptions = [];

    if (role_names === "Manager") {
      // If role is manager, set to names
      reportingToOptions = [names];
    } else if (role_names === "Teamlead") {
      // If role is teamlead, show the list of managers from userList
      const managers = userList
        .filter((user) => user.role_name === "Manager")
        .map((user) => user.user);

      reportingToOptions = managers.length > 0 ? managers : [names];
    } else if (role_names === "employee") {
      // If role is employee, show the list of teamleads from userList
      const teamleads = userList
        .filter((user) => user.role_name === "Teamlead")
        .map((user) => user.user);

      reportingToOptions = teamleads.length > 0 ? teamleads : [names];
    } else if (role_names === "admin") {
      // If role is employee, show the list of teamleads from userList
      const teamleads = userList
        .filter((user) => user.role_name !== "employee")
        .map((user) => user.user);

      reportingToOptions = teamleads.length > 0 ? teamleads : [names];
    }

    setReportingTo(reportingToOptions);
  };

  // Function to get reportingTo options based on user roles
  const getReportingToOptions1 = () => {
    let reportingToOptions = [];

    // Set initial reporting_to value based on the user's role
    if (roles === "admin") {
      const admins = userList
        .filter((user) => user.role_name !== "employee")
        .map((user) => user.user);

      reportingToOptions = admins.length > 0 ? admins : [names];
    } else if (roles === "Manager") {
      const managers = userList
        .filter((user) => user.role_name === "Teamlead")
        .map((user) => user.user);

      reportingToOptions = managers.length > 0 ? managers : [names];
    } else if (roles === "Teamlead") {
      const teamleads = userList
        .filter((user) => user.role_name === "employee")
        .map((user) => user.user);

      reportingToOptions = teamleads.length > 0 ? teamleads : [names];
    }
    setReportingTo(reportingToOptions);
  };

  // Effect to update Roles and reportingTo when roles or selected role changes
  useEffect(() => {
    RoleType();
    setFilteredData((prevData) => ({
      ...prevData,
      reporting_to: "",
    }));

    // Check if role_name is present, if yes use getReportingToOptions, else use getReportingToOptions1
    filteredData.role_name
      ? getReportingToOptions(filteredData.role_name, names)
      : getReportingToOptions1();
    console.log(names);
  }, [roles, filteredData.role_name]);

  // Function to map role options for dropdown
  const roletype = () =>
    Roles.map((role) => (
      <option key={role} value={role}>
        {role}
      </option>
    ));

  // Function to handle filter application
  const handleFilter = () => {
    onFilter(filteredData);
    hide();
  };

  // Function to reset filters
  const ResetFilter = () => {
    const resetData = {
      user: "",
      role_name: "",
      reporting_to: "",
      created_date1: "",
      created_date2: "",
      last_login1: "",
      last_login2: "",
    };

    setFilteredData(resetData);
    onFilter(resetData);
    hide();
  };

  return (
    <div className="filter-box">
      <div className="popover-title">Filter</div>
      <div className="filter-body">
        <div className="filter-Sub">User</div>
        <input
          type="text"
          className="input-filter"
          value={filteredData?.user || ""}
          onChange={(e) => {
            setFilteredData({
              ...filteredData,
              user: e.target.value,
            });
          }}
        />
        <div className="filter-Sub">Role Name</div>
        <select
          // className="user-inpup-drop"
          className="form-select input-filter"
          aria-label="Default select example"
          value={filteredData?.role_name || ""}
          style={{ textTransform: "capitalize" }}
          onChange={(e) =>
            setFilteredData({
              ...filteredData,
              role_name: e.target.value,
            })
          }
        >
          <option value={""}>Select User Role</option>
          {roletype()}
        </select>
        <div className="filter-Sub">Reporting to</div>
        <select
          // className="user-inpup-drop"
          className="form-select input-filter"
          aria-label="Default select example"
          value={filteredData?.reporting_to || ""}
          style={{ textTransform: "capitalize" }}
          onChange={(e) =>
            setFilteredData({
              ...filteredData,
              reporting_to: e.target.value,
            })
          }
        >
          <option value={""}>Select Reporting to</option>

          {reportingTo &&
            reportingTo?.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
        </select>
        <div className="filter-Sub">Created Date</div>
        <div className="date-filter">
          <div className="date-f1">
            <div className="sub-date">From</div>
            <input
              type="date"
              className="input-filter"
              value={filteredData?.created_date1 || ""}
              onChange={(e) => {
                setFilteredData({
                  ...filteredData,
                  created_date1: e.target.value,
                });
              }}
            />
          </div>
          <div className="date-f1">
            <div className="sub-date">To</div>
            <input
              type="date"
              className="input-filter"
              value={filteredData?.created_date2 || ""}
              onChange={(e) => {
                setFilteredData({
                  ...filteredData,
                  created_date2: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className="filter-Sub">Last Login</div>
        <div className="date-filter">
          <div className="date-f1">
            <div className="sub-date">From</div>
            <input
              type="date"
              className="input-filter"
              value={filteredData?.last_login1 || ""}
              onChange={(e) => {
                setFilteredData({
                  ...filteredData,
                  last_login1: e.target.value,
                });
              }}
            />
          </div>
          <div className="date-f1">
            <div className="sub-date">To</div>
            <input
              type="date"
              className="input-filter"
              value={filteredData?.last_login2 || ""}
              onChange={(e) => {
                setFilteredData({
                  ...filteredData,
                  last_login2: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="filter-btn">
        <div>
          <Button
            btnname={"Reset"}
            bg={"#fff"}
            cl={"#000"}
            border={"1px solid #1E6641"}
            onclick={ResetFilter}
          />
        </div>
        <div>
          <Button
            btnname={"Apply Now"}
            onclick={handleFilter}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleFilter();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterUser;
