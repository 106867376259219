import React, { useState, useRef, useCallback } from "react";
import Button from "../../Ui/Button/Button";
// import search from "../../Assets/HeatNumber/heat search.png";
import upload from "../../Assets/HeatNumber/heat fileupload.png";
import deleteicon from "../../Assets/HeatNumber/deleteIcon.svg";
import "./Quality.scss";
import SnackBar from "../../Ui/SnackBar";
import _http from "../../Utils/Api/_http";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
const HeatNumber = () => {
  const [heatNumbers, setHeatNumbers] = useState([""]);
  const [result, setResult] = useState([]);
  const [file, setFile] = useState();
  const [files, setFiles] = useState(false);
  const fileInputRef = useRef(null);
  const [MTCdata, setMTCdata] = useState({ company_name: "", po_no: "" });
  const [mtc, setMtc] = useState(false);
  const [heat, setHeat] = useState(false);
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const baseFontSize = 16;

  const rem = (pixels, context = baseFontSize) => {
    if (typeof pixels === "number") {
      pixels = pixels + "px";
    }

    if (typeof context === "number") {
      context = context + "px";
    }

    // Extract numeric values from pixel strings
    const pixelValue = parseFloat(pixels);
    const contextValue = parseFloat(context);

    return `${pixelValue / contextValue}rem`;
  };

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const deleteRow = useCallback(
    (index) => {
      const newHeatNumbers = [...heatNumbers];
      newHeatNumbers.splice(index, 1);
      setHeatNumbers(newHeatNumbers);
    },
    [heatNumbers]
  );

  const fetchData = useCallback(async () => {
    setHeat(true);
    const nonEmptyHeatNumbers = heatNumbers.filter(
      (heat) => heat.trim() !== ""
    );

    if (nonEmptyHeatNumbers.length === 0) {
      setError("Please add Heat Number");
      setToast({ error: true });
      setHeat(false);
      return;
    } else {
      setHeatNumbers(nonEmptyHeatNumbers);

      try {
        const response = await _http.post(
          `https://autodesk.dtskill.com/heat_number_check`,
          {
            heat_numbers: nonEmptyHeatNumbers,
          }
        );
        setResult(response.data);
      } catch (error) {
        setToast({ error: true });
        setError(error.message);
      }
    }
    setHeat(false);
  }, [heatNumbers]);

  const uploadPdf = async () => {
    if (!file) {
      setToast({ error: true });
      setError("Please select a file");
    } else {
      setFiles(true);
      try {
        const formData = new FormData();
        formData.append("file", file[0]);
        const response = await _http.post(
          `https://autodesk.dtskill.com/upload_pdf`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setFile();
        setToast(
          (response.data.message && { submit: true }) ||
            (response.data.error && { error: true })
        );
        setError(response.data.message || response.data.error);
      } catch (error) {
        setToast({ error: true });
        setError(error.message);
      }
      setFiles(false);
    }
  };

  const generateMTC = async () => {
    if (!heatNumbers || !MTCdata.company_name || !MTCdata.po_no) {
      setError("Please fill the input fields");
      setToast({ error: true });
      return;
    }

    // Check if any heat number has a status of 'no'
    const noHeatNumbers = heatNumbers.filter((heat) => {
      const resultIndex = result.findIndex((item) => item.number === heat);
      return resultIndex !== -1 && result[resultIndex].status === "no";
    });

    // If there are heat numbers with status 'no', set toast and error
    if (noHeatNumbers.length > 0) {
      setError("Please upload a PDF for the heat number(s) with status 'no'.");
      setToast({ error: true });
      return;
    }

    setMtc(true);
    try {
      const response = await _http.post(
        `https://autodesk.dtskill.com/doc_creation`,
        {
          heat_no: heatNumbers,
          company_name: MTCdata.company_name,
          po_no: MTCdata.po_no,
        },
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${MTCdata.company_name}.docx`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      // const url = URL.createObjectURL(blob);

      // sessionStorage.setItem("url", url);
      // // Open the generated document in a new tab/window
      // const newTab = window.open("/fileviewer", "_blank");

      // // Optionally focus the new tab
      // if (newTab) {
      //   newTab.focus();
      // }
      setHeatNumbers([""]);
      setMTCdata({ company_name: "", po_no: "" });
      setError("File generated successfully");
      setToast({ submit: true });
    } catch (error) {
      setToast({ error: true });
      setError(error.message);
    }
    setMtc(false);
  };

  const addHeatBox = useCallback(() => {
    setHeatNumbers([...heatNumbers, ""]);
  }, [heatNumbers]);

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log("Dropped files:", files);
  };

  const handleInputChange = (index, value) => {
    const newHeatNumbers = [...heatNumbers];
    newHeatNumbers[index] = value;
    setHeatNumbers(newHeatNumbers);
  };

  return (
    <section>
      <div
        className="container1"
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div className="heat-heading">Heat Number</div>
        <div className="heat-search">
          <TextField
            label="Company Name"
            className="heat-search-input"
            type="text"
            value={MTCdata.company_name}
            // placeholder="Company Name"
            onChange={(e) =>
              setMTCdata({ ...MTCdata, company_name: e.target.value })
            }
            // data-tooltip-id="tooltip-arrow"
            // data-tooltip-content="Company Name"
          />
          <TextField
            type="text"
            label="PO Number"
            className="heat-search-input"
            value={MTCdata.po_no}
            // placeholder="PO Number"
            onChange={(e) => setMTCdata({ ...MTCdata, po_no: e.target.value })}
          />
        </div>
        <div className="heat-search" style={{ marginLeft: rem(40) }}>
          {heatNumbers.map((heat, index) => {
            const resultIndex = result.findIndex(
              (item) => item.number === heat
            );
            const resultStatus =
              resultIndex !== -1 && result[resultIndex].status;

            return (
              <div
                className={`heat-box position-relative 
             
                `}
                key={index}
              >
                <TextField
                  label="Search heat number here"
                  type="text"
                  // placeholder="Search heat number here"
                  className={`heat-box-1 ${
                    resultStatus === "yes"
                      ? "true-heat"
                      : resultStatus === "no"
                      ? "false-heat"
                      : "default-border"
                  }`}
                  autoSave="true"
                  value={heat}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                {/* {index === 0 && <img src={search} alt="img" />} */}
                {index !== 0 && (
                  <img
                    src={deleteicon}
                    alt="img"
                    onClick={() => deleteRow(index)}
                    style={{ cursor: "pointer" }}
                  />
                )}
                {resultStatus === "no" && (
                  <span className="position-absolute heat-comment">
                    * Heat number unavailable, upload the PDF below
                  </span>
                )}
              </div>
            );
          })}
          <div className="d-flex gap-4 align-items-center">
            {heatNumbers[0]?.trim() !== "" && (
              <Button
                btnname={"+"}
                onclick={addHeatBox}
                height={35}
                width={35}
              />
            )}
            <Button
              btnname={heat ? "...Checking" : "Check"}
              width={96}
              height={40}
              onclick={fetchData}
            />
          </div>
          <div className="d-flex justify-content-center w-100 align-items-center">
            <Button
              btnname={mtc ? "...Generating" : "Generate MTC file"}
              onclick={generateMTC}
              height={40}
            />
          </div>
        </div>
        <hr className="heat-line" />
        <div className="heat-upload" style={{ marginLeft: rem(40) }}>
          <div
            className={`file-upload`}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDrop={handleDrop}
            onClick={handleFileUpload}
          >
            <img src={upload} className="mx-2" alt="icon" />
            <p>Drag and drop or</p>
            <p>select a file to upload</p>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={(e) => {
              setFile(e.target.files);
            }}
          />
          <Button
            btnname={files ? "Uploading..." : "Upload"}
            width={96}
            height={40}
            onclick={uploadPdf}
          />
        </div>
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </section>
  );
};

export default HeatNumber;
