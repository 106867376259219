// Importing necessary dependencies and styles
import React from "react";
import "./Button.scss";

// Define the base font size
const baseFontSize = 16;

/**
 * Converts pixels to rem units
 * @param {number} pixels - The pixel value to convert
 * @param {number} context - The context for conversion (defaults to baseFontSize)
 * @returns {string} - The value in rem units
 */
function rem(pixels, context = baseFontSize) {
  // Ensure the input is a number
  if (typeof pixels !== "number" || typeof context !== "number") {
    throw new Error("Both pixels and context should be numbers");
  }

  // Perform the calculation
  const remValue = pixels / context;

  // Return the result in rem units
  return `${remValue}rem`;
}

// Functional component for a custom button
const Button = ({
  btnname, // Text content of the button
  onclick, // Function to be called when the button is clicked
  width, // Width of the button
  height, // Height of the button
  fs, // Font size of the button text
  fw, // Font weight of the button text
  cl, // Text color of the button text
  bg, // Background color of the button
  border, // Border style of the button
}) => {
  // Create the style object dynamically
  const style = {
    ...(typeof width === "number" && { width: rem(width) }), // Custom width style
    ...(typeof height === "number" && { height: rem(height) }), // Custom height style
    ...(typeof fs === "number" && { fontSize: rem(fs) }), // Custom font size style
    fontWeight: fw, // Custom font weight style
    backgroundColor: bg, // Custom background color style
    color: cl, // Custom text color style
    border: border, // Custom border style
  };

  // Rendering a button with specified styles and onClick handler
  return (
    <button
      onClick={onclick} // Event handler for button click
      className="spira-ui-btn" // CSS class for styling the button
      style={style} // Applying the dynamically created style object
    >
      {/* Displaying the text content of the button */}
      {btnname}
    </button>
  );
};

// Exporting the Button component
export default Button;
