import React from "react";

const Report = () => {
  return (
    <div className="d-flex align-items-center justify-content-center w-100 ">
      Report
    </div>
  );
};

export default Report;
