import { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

::-webkit-scrollbar-thumb {
    background-color: #011c226b;
    background-clip: content-box;
    border-radius: 10px;
    width: 8px;
  }

::-webkit-scrollbar-thumb:hover {
    background-color: #011c2299;
  }
`;

// Other global styles can be added here

export default GlobalStyle;
