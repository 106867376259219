// Importing necessary dependencies from Material-UI
import React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

// Creating a custom Alert component using MuiAlert
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Functional component for displaying a Snackbar with an Alert
const SnackBar = ({ message, severity, Open, handleClose }) => {
  const baseFontSize = 16;

  const rem = (pixels, context = baseFontSize) => {
    if (typeof pixels === "number") {
      pixels = pixels + "px";
    }

    if (typeof context === "number") {
      context = context + "px";
    }

    // Extract numeric values from pixel strings
    const pixelValue = parseFloat(pixels);
    const contextValue = parseFloat(context);

    return `${pixelValue / contextValue}rem`;
  };
  return (
    // Snackbar component from Material-UI
    <Snackbar
      open={Open} // Boolean value to determine whether the Snackbar is open or not
      autoHideDuration={3000} // Time duration (in milliseconds) for the Snackbar to automatically hide
      onClose={handleClose} // Function to be called when the Snackbar is closed
      anchorOrigin={{ vertical: "top", horizontal: "right" }} // Position of the Snackbar
      sx={{
        margin: `${rem(65)} ${rem(15)}`, // Custom styling for the Snackbar
      }}
    >
      {/* Custom Alert component inside the Snackbar */}
      <Alert
        onClose={handleClose} // Function to be called when the Alert is closed
        severity={severity} // Severity level of the Alert (e.g., "success", "error", "info")
        sx={{
          height: rem(60), // Custom styling for the Alert component
          // width: "100%",
          display: "flex",
          alignItems: "center",
          fontSize: rem(15),
          padding: `0 ${rem(15)}`,
        }}
      >
        {message} {/* Message content to be displayed in the Alert */}
      </Alert>
    </Snackbar>
  );
};

// Exporting the SnackBar component
export default SnackBar;
