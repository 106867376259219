import React, { useState } from "react";
import { Select, Modal, InputNumber, Table } from "antd";
import "./Quotegenerator.scss";
import Button from "../../Ui/Button/Button";
import _http from "../../Utils/Api/_http";
// import QuotePopup from "./QuotePopup";

const QuoteGenerator = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [add, setAdd] = useState(false);
  const [generate, setGenerate] = useState(false);
  const [formdata, setFormdata] = useState({
    item: "SWG",
    winding_material: "",
    filler_material: "GR",
    inner_ring_material: "",
    outer_ring_material: "",
    material_size: "",
    rating: "",
  });
  const [tableData, setTableData] = useState({
    quantity: 100,
    currency: "AED",
    local_tax: 10,
    margin: 50,
  });
  const [quote, setQuote] = useState();
  const [data_1, setCombinedTableData] = useState([]);
  const [data_2, setFurtherDetail] = useState({
    c_name: "",
    att: "",
    add: "",
    date: "",
    dely: "",
    ship: "",
  });
  const columns = [
    {
      title: "Sl.No",
      dataIndex: "slNo",
      key: "slNo",
    },
    {
      title: "Product Description",
      dataIndex: "desp",
      key: "desp",
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Unit rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: "Margin",
      dataIndex: "margin",
      key: "margin",
    },
    {
      title: "Quote",
      dataIndex: "quote",
      key: "quote",
    },
  ];

  const baseFontSize = 16;

  const rem = (pixels, context = baseFontSize) => {
    if (typeof pixels === "number") {
      pixels = pixels + "px";
    }

    if (typeof context === "number") {
      context = context + "px";
    }

    // Extract numeric values from pixel strings
    const pixelValue = parseFloat(pixels);
    const contextValue = parseFloat(context);

    return `${pixelValue / contextValue}rem`;
  };

  const handleQuote = async () => {
    if (
      !formdata.winding_material ||
      !formdata.rating ||
      !formdata.material_size ||
      !formdata.outer_ring_material
    ) {
      alert("Please fill all the fields");
      return;
    }
    setAdd(true);
    try {
      const response = await _http.post(
        "https://autodesk.dtskill.com/price_list",
        formdata, // Send formdata directly, not wrapped in an object
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data[0]?.flat()[0] === "") {
        alert("please change the material combination");
        return;
      }
      const fetchedQuote = response?.data[0]?.flat()[0]; // Retrieve the actual quote value
      setQuote(fetchedQuote);
      const newEntry = {
        slNo: data_1.length + 1,
        desp: Object.values(formdata).join(", "),
        qty: tableData.quantity,
        rate: `${fetchedQuote} ${tableData.currency}`,
        cost: `${tableData.quantity * fetchedQuote} ${tableData.currency}`,
        margin: `${tableData.margin}%`,
        quote: `${(
          tableData.quantity *
          fetchedQuote *
          (1 + tableData.margin / 100)
        ).toFixed(2)} ${tableData.currency}`,
      };

      // Append new data to combinedTableData
      setCombinedTableData((prevData) => [...prevData, newEntry]);
      setFormdata({
        item: "SWG",
        winding_material: "",
        filler_material: "GR",
        inner_ring_material: "",
        outer_ring_material: "",
        material_size: "",
        rating: "",
      });
      setTableData({ ...tableData, quantity: "", margin: "" });
    } catch (error) {
      console.error(error);
    }
    setAdd(false);
  };

  const handleChange = (value, field) => {
    setFormdata((prevFormdata) => ({
      ...prevFormdata,
      [field]: value,
    }));
  };
  const handleChangedata = (value, field) => {
    setTableData((prevTableData) => ({
      ...prevTableData,
      [field]: value,
    }));
  };
  const handleFurtherData = (value, name) => {
    setFurtherDetail({ ...data_2, [name]: value });
  };
  const handleReset = () => {
    setFormdata({
      item: "SWG",
      winding_material: "",
      filler_material: "GR",
      inner_ring_material: "",
      outer_ring_material: "",
      material_size: "",
      rating: "",
    });
    setTableData({ quantity: "", margin: "", currency: "AED", tax: "" });
    setQuote("");
    setCombinedTableData([]);
  };
  const handleGenerate = async () => {
    if (
      !data_2.add ||
      !data_2.att ||
      !data_2.c_name ||
      !data_2.date ||
      !data_2.dely ||
      !data_2.ship
    ) {
      alert("please fill all the fields");
    }
    setGenerate(true);
    try {
      const response = await _http.post(
        "https://autodesk.dtskill.com/quote_file",
        {
          data_1,
          data_2,
          Tcost: data_1.reduce(
            (total, item) => total + parseFloat(item.quote.split(" ")[0]),
            0
          ),
          tax: Tcost * (tableData.local_tax / 100),
          f_tax: tax + Tcost,
        }, // Send formdata directly, not wrapped in an object
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      setModalContent(
        <embed
          width={"100%"}
          height={"100%"}
          src={URL.createObjectURL(blob)}
          type="application/pdf"
        />
      );
      setModalVisible(true);
      // Append new data to combinedTableData
      setFurtherDetail({
        c_name: "",
        att: "",
        add: "",
        date: "",
        dely: "",
        ship: "",
      });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
    setGenerate(false);
  };

  const options1 = [{ value: "SWG", label: "SWG" }];
  const options2 = [
    { value: "SS 316", label: "SS 316" },
    { value: "SS 304", label: "SS 304" },
  ];
  const options3 = [{ value: "GR", label: "GR" }];
  const options4 = [
    { value: "SS 316", label: "SS 316" },
    { value: "SS 304", label: "SS 304" },
  ];

  const options5 = [
    { value: "CS", label: "CS" },
    { value: "316", label: "316" },
    { value: "304", label: "304" },
  ];

  const options6 = [
    { value: "0.5", label: "0.5" },
    { value: "0.75", label: "0.75" },
    { value: "1", label: "1" },
    { value: "1.25", label: "1.25" },
    { value: "1.5", label: "1.5" },
    { value: "2", label: "2" },
    { value: "2.5", label: "2.5" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "8", label: "8" },
    { value: "10", label: "10" },
    { value: "12", label: "12" },
    { value: "14", label: "14" },
    { value: "16", label: "16" },
    { value: "18", label: "18" },
    { value: "20", label: "20" },
    { value: "24", label: "24" },
  ];

  const options7 = [
    { value: "150", label: "150" },
    { value: "300", label: "300" },
    { value: "600", label: "600" },
    { value: "900", label: "900" },
    { value: "1500", label: "1500" },
    { value: "2500", label: "2500" },
  ];

  const currency = [
    { value: "USD", label: "USD" },
    { value: "AED", label: "AED" },
  ];

  // Calculate totals
  const Tcost = data_1.reduce(
    (total, item) => total + parseFloat(item.quote.split(" ")[0]),
    0
  );
  const tax = Tcost * (tableData.local_tax / 100);
  const ftax = Tcost + tax;

  const summaryData = [
    {
      key: "Tcost",
      margin: "Total Cost",
      quote: `${Tcost.toFixed(2)} ${tableData.currency}`,
    },
    {
      key: "tax",
      margin: "Tax Value",
      quote: `${tax.toFixed(2)} ${tableData.currency}`,
    },
    {
      key: "ftax",
      margin: "Total Cost Including Tax",
      quote: `${ftax.toFixed(2)} ${tableData.currency}`,
    },
  ];

  return (
    <section>
      <div className="container1">
        <div className="container-head">
          <p className="title">Quote Generator</p>
        </div>
        <div className="Quote-input">
          <div className="">
            <div className="sub-head">Gasket Material</div>
            <Select
              size={"large"}
              onChange={(value) => handleChange(value, "item")}
              style={{ width: rem(323) }}
              options={options1}
              placeholder={"Material"}
              defaultValue={"SWG"}
              value={formdata.item}
            />
          </div>
          <div className="">
            <div className="sub-head">Winding Material</div>
            <Select
              size={"large"}
              onChange={(value) => handleChange(value, "winding_material")}
              style={{ width: rem(323) }}
              options={options2}
              placeholder={"Winding Material"}
              value={formdata.winding_material}
            />
          </div>{" "}
          <div className="">
            <div className="sub-head">Filler Material</div>
            <Select
              size={"large"}
              onChange={(value) => handleChange(value, "filler_material")}
              style={{ width: rem(323) }}
              options={options3}
              placeholder={"Filler Material"}
              defaultValue={"GR"}
              value={formdata.filler_material}
            />
          </div>{" "}
          <div className="">
            <div className="sub-head">Inner Ring Material</div>
            <Select
              size={"large"}
              onChange={(value) => handleChange(value, "inner_ring_material")}
              style={{ width: rem(323) }}
              options={options4}
              placeholder={"Inner Ring Material"}
              value={formdata.inner_ring_material}
            />
          </div>{" "}
          <div className="">
            <div className="sub-head">Outer Ring Material</div>
            <Select
              size={"large"}
              onChange={(value) => handleChange(value, "outer_ring_material")}
              style={{ width: rem(323) }}
              options={options5}
              placeholder={"Outer Ring Material"}
              value={formdata.outer_ring_material}
            />
          </div>{" "}
          <div className="">
            <div className="sub-head">Size</div>
            <Select
              size={"large"}
              onChange={(value) => handleChange(value, "material_size")}
              style={{ width: rem(323) }}
              options={options6}
              placeholder={"Size"}
              value={formdata.material_size}
              className="quote-dropdown"
            />
          </div>
          <div className="">
            <div className="sub-head">Rating</div>
            <Select
              size={"large"}
              onChange={(value) => handleChange(value, "rating")}
              style={{ width: rem(323) }}
              options={options7}
              placeholder={"Rating"}
              value={formdata.rating}
            />
          </div>
          <div className="">
            <div className="sub-head">Quantity</div>
            <InputNumber
              defaultValue={100}
              className="quote-currency quantity"
              min={1}
              value={tableData.quantity}
              onChange={(value) => handleChangedata(value, "quantity")}
            />
          </div>{" "}
          <div className="">
            <div className="sub-head">Currency</div>
            <Select
              defaultValue={"AED"}
              className="quote-currency"
              value={tableData.currency}
              onChange={(value) => handleChangedata(value, "currency")}
              size={"large"}
              style={{ width: rem(323) }}
              options={currency}
            />
          </div>{" "}
          <div className="">
            <div className="sub-head">Local Tax</div>
            <InputNumber
              addonAfter={
                <div style={{ width: rem(40), fontSize: rem(16) }}>%</div>
              }
              defaultValue={10}
              className="quote-currency"
              min={0}
              value={tableData.local_tax}
              onChange={(value) => handleChangedata(value, "local_tax")}
            />
          </div>
          <div className="">
            <div className="sub-head">Margin</div>
            <InputNumber
              addonAfter={
                <div style={{ width: rem(40), fontSize: rem(16) }}>%</div>
              }
              defaultValue={50}
              className="quote-currency"
              min={0}
              value={tableData.margin}
              onChange={(value) => handleChangedata(value, "margin")}
            />
          </div>
        </div>
        <div className="quote-btn">
          <Button btnname={"CLEAR"} onclick={handleReset} />

          <Button btnname={add ? "ADDING..." : "ADD"} onclick={handleQuote} />
        </div>
        <div className="table-wrap">
          <Table
            columns={columns}
            dataSource={quote && [...data_1, ...summaryData]}
            pagination={false}
          />
        </div>
        <div className="quote-bottom">
          <div style={{ width: rem(323) }}>
            <div className="sub-head">Company Name</div>
            <input
              className="input-filter"
              style={{ height: rem(40) }}
              value={data_2.c_name}
              onChange={(e) => {
                handleFurtherData(e.target.value, "c_name");
              }}
            />
          </div>
          <div style={{ width: rem(323) }}>
            <div className="sub-head">Attention to</div>
            <input
              className="input-filter"
              style={{ height: rem(40) }}
              value={data_2.att}
              onChange={(e) => {
                handleFurtherData(e.target.value, "att");
              }}
            />
          </div>{" "}
          <div style={{ width: rem(323) }}>
            <div className="sub-head">Company Address</div>
            <input
              className="input-filter"
              style={{ height: rem(40) }}
              value={data_2.add}
              onChange={(e) => {
                handleFurtherData(e.target.value, "add");
              }}
            />
          </div>{" "}
          <div style={{ width: rem(323) }}>
            <div className="sub-head">Date</div>
            <input
              className="input-filter"
              style={{ height: rem(40) }}
              type="date"
              value={data_2.date}
              onChange={(e) => {
                handleFurtherData(e.target.value, "date");
              }}
            />
          </div>{" "}
          <div style={{ width: rem(323) }}>
            <div className="sub-head">Delivery Timelines</div>
            <input
              className="input-filter"
              style={{ height: rem(40) }}
              value={data_2.dely}
              onChange={(e) => {
                handleFurtherData(e.target.value, "dely");
              }}
            />
          </div>{" "}
          <div style={{ width: rem(323) }}>
            <div className="sub-head">Mode of Shipment</div>
            <input
              className="input-filter"
              style={{ height: rem(40) }}
              value={data_2.ship}
              onChange={(e) => {
                handleFurtherData(e.target.value, "ship");
              }}
            />
          </div>
        </div>
        <div className="quote-btn">
          <Button
            btnname={generate ? "GENERATING QUOTE..." : "GENERATE QUOTE"}
            onclick={handleGenerate}
          />
        </div>
      </div>
      <Modal
        title="Docx Preview"
        className="feedback-pdf"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
      >
        {modalContent}
      </Modal>
    </section>
  );
};

export default QuoteGenerator;
