import React, { useEffect, useState } from "react";
import mammoth from "mammoth";

const DocumentViewer = () => {
  const [htmlContent, setHtmlContent] = useState(null);

  useEffect(() => {
    const generatedDocUrl = sessionStorage.getItem("url");

    if (generatedDocUrl) {
      fetch(generatedDocUrl)
        .then((response) => response.blob())
        .then((blob) => {
          mammoth
            .convertToHtml({ arrayBuffer: blob })
            .then((result) => {
              const html = handleImagesAndTables(result.value);
              setHtmlContent(html);
            })
            .catch((error) => {
              console.error("Error converting document to HTML:", error);
            });
        })
        .catch((error) => {
          console.error("Error fetching document:", error);
        });
    } else {
      console.error("Generated document URL not found in sessionStorage");
    }
  }, []);

  const handleImagesAndTables = (html) => {
    // Handle images
    html = html.replace(
      /<img [^>]*src="([^"]+)"[^>]*>/g,
      '<img src="$1" style="max-width: 100%;" />'
    );

    // Handle tables
    html = html.replace(
      /<table/g,
      '<table style="width: 100%; border-collapse: collapse;"'
    );
    html = html.replace(/<tr/g, '<tr style="border-bottom: 1px solid #ccc;"');
    html = html.replace(
      /<th/g,
      '<th style="border: 1px solid #ccc; padding: 8px;"'
    );
    html = html.replace(
      /<td/g,
      '<td style="border: 1px solid #ccc; padding: 8px;"'
    );

    return html;
  };

  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default DocumentViewer;
