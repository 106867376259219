import React, { useState } from "react";
import FeedbackTable from "./FeedbackTable";
// import { Popover, Space } from "antd";
// import down from "../../Assets/EnquiresIcon/down.svg";
// import filter from "../../Assets/EnquiresIcon/filter.svg";
// import filteron from "../../Assets/EnquiresIcon/filteron.svg";
import "../SalesTracker/STcomponents/SalesTracker/SalesTracker.scss";
import "./Feedback.scss";

const Feedback = () => {
  // const [filterOn, setFilterOn] = useState(false);
  return (
    <section>
      <div className="container1">
        <div className="container-head">
          <p className="title">Feedback</p>
          {/* <Popover
            trigger="click"
            // open={openfilter}
            placement="bottomRight"
            // onOpenChange={handleOpenChange}
            // content={<FilterEnquires onFilter={handleFilter} hide={hide} />}
          >
            <Space>
              <span
                className="filter-head"
                data-tooltip-id="tooltip-arrow"
                data-tooltip-content="Filter"
              >
                <img src={filterOn ? filteron : filter} alt="icon" />{" "}
                <img src={down} alt="icon" />
              </span>
            </Space>
          </Popover> */}
        </div>
        <FeedbackTable />
      </div>
    </section>
  );
};

export default Feedback;
