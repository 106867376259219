// Navbar.jsx
import React, { useState } from "react";
import "./Navbar.scss";
import SpiraLogo from "./../../Assets/NavbarIcon/spira 1.svg";
import Notification from "./../../Assets/NavbarIcon/notifications.svg";
import SearchIcon from "./../../Assets/NavbarIcon/search.svg";
import Profile from "./../../Assets/NavbarIcon/profile.svg";
import { Link } from "react-router-dom";
import { Popover, Space } from "antd";
import ProfileDropdown from "./ProfileDropdown";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { selectUserData } from "../../Redux/Reducers";
// Navbar component that displays the navigation bar at the top of the page
const Navbar = () => {
  // State to manage the visibility of the profile dropdown
  const [open, setOpen] = useState(false);
  const username = useSelector(selectUserData);
  // Function to handle changes in the open state of the profile dropdown
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <nav className="main-nav-tab">
      <div className="nav-container">
        {/* Logo section with a link to the home page */}
        <div>
          <Link to="/SpiraAI/Dashboard">
            <img className="logo" src={SpiraLogo} alt="Icon" />
          </Link>
        </div>

        {/* Navigation right section with search bar, notifications, and profile */}
        <div className="nav-right">
          {/* Search bar */}
          <div className="df nav-r1">
            <img src={SearchIcon} alt="icon" />
            <input placeholder="Search" />
          </div>

          {/* Notifications and Profile sections */}
          <div className="df nav-r2">
            {/* Notifications section */}
            <Link to="#">
              <div
                className="df nav-r21"
                data-tooltip-id="tooltip-arrow"
                data-tooltip-content="Notifications"
              >
                <img src={Notification} alt="icon" />
                <p>2</p>
              </div>
            </Link>

            {/* Profile section with a Popover for the profile dropdown */}
            <Popover
              content={<ProfileDropdown />}
              trigger="click"
              open={open}
              placement="bottomRight"
              onOpenChange={handleOpenChange}
            >
              <Space>
                <div className="df user-img">
                  <img src={Profile} alt="icon" />
                  <p>{username.Name}</p>
                </div>
              </Space>
            </Popover>
          </div>
        </div>
      </div>

      {/* Tooltip for notifications icon */}
      <Tooltip
        id="tooltip-arrow"
        place="bottom"
        style={{ fontSize: "16px", padding: "7px" }}
      />
    </nav>
  );
};

export default Navbar;
